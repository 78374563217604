const Logger = require('nordic/logger')('sanitizePreloadedState');

const removeQueryParamFromUrl = (url, queryParamKey) => {
  try {
    const urlInstance = new URL(url);
    if (!urlInstance.searchParams.has(queryParamKey)) {
      return url;
    }

    urlInstance.searchParams.delete(queryParamKey);
    return urlInstance.toString();
  } catch (e) {
    return url;
  }
};

const removeQueryParamFromPath = (path, queryParamKey) => {
  try {
    const urlInstance = new URL(path, 'http://dummyurl.com');
    if (!urlInstance.searchParams.has(queryParamKey)) {
      return path;
    }

    urlInstance.searchParams.delete(queryParamKey);
    return urlInstance.pathname + urlInstance.search;
  } catch (e) {
    return path;
  }
};

const removeAccessTokenFromUrl = (url) => {
  const urlWithoutAccessTokenCamelCase = removeQueryParamFromUrl(
    url,
    'accessToken',
  );
  return removeQueryParamFromUrl(
    urlWithoutAccessTokenCamelCase,
    'access_token',
  );
};

const removeAccessTokenFromUrlQueryParam = (url, queryParamKey) => {
  try {
    const urlInstance = new URL(url);
    if (!urlInstance.searchParams.has(queryParamKey)) {
      return url;
    }

    const queryParamUrlInstance = new URL(
      decodeURIComponent(urlInstance.searchParams.get(queryParamKey)),
    );

    if (
      !queryParamUrlInstance.searchParams.has('accessToken')
      && !queryParamUrlInstance.searchParams.has('access_token')
    ) {
      return url;
    }

    queryParamUrlInstance.searchParams.delete('accessToken');
    queryParamUrlInstance.searchParams.delete('access_token');

    urlInstance.searchParams.set(
      queryParamKey,
      encodeURIComponent(queryParamUrlInstance.toString()),
    );

    return urlInstance.toString();
  } catch (e) {
    return url;
  }
};

const removeAccessTokenFromPath = (path) => {
  const pathWithoutAccessTokenCamelCase = removeQueryParamFromPath(
    path,
    'accessToken',
  );
  return removeQueryParamFromPath(
    pathWithoutAccessTokenCamelCase,
    'access_token',
  );
};

const removeTokensFromString = (str = '') => {
  const regex = /(access_token=[\w-]*|accessToken=[\w-]*|cookies=[\w-]*|token=[\w-]*|(?:TEST|APP_USR|ADM|APP|access_token)[-_\w]*=?[^&]*)&?/g;
  return str.replace(regex, '').replace(/[?&]$/, '');
};

const removeAccessTokenFromPreloadedState = (preloadedState) => {
  const preloadedStateCopy = { ...preloadedState };

  if (preloadedStateCopy.actualLocation) {
    preloadedStateCopy.actualLocation = removeAccessTokenFromUrl(
      preloadedStateCopy.actualLocation,
    );
  }

  if (preloadedStateCopy.referrer) {
    preloadedStateCopy.referrer = removeAccessTokenFromUrl(
      preloadedStateCopy.referrer,
    );
  }

  if (preloadedStateCopy.urlState && preloadedStateCopy.urlState.headers && preloadedStateCopy.urlState.headers.referer) {
    const urlStateCopy = {
      ...preloadedStateCopy.urlState,
      headers: {
        ...preloadedStateCopy.urlState.headers,
        referer: removeAccessTokenFromUrl(
          preloadedStateCopy.urlState.headers.referer,
        ),
      },
    };

    preloadedStateCopy.urlState = urlStateCopy;
  }

  if (preloadedStateCopy.urls) {
    const urlsWithoutAccessToken = {};

    Object.keys(preloadedStateCopy.urls)
      .forEach((key) => {
        urlsWithoutAccessToken[key] = { ...preloadedStateCopy.urls[key] };

        if (urlsWithoutAccessToken[key].link) {
          let link = removeAccessTokenFromUrl(urlsWithoutAccessToken[key].link);
          link = removeAccessTokenFromUrlQueryParam(link, 'go');
          link = removeAccessTokenFromUrlQueryParam(link, 'confirmation_url');

          urlsWithoutAccessToken[key] = {
            ...urlsWithoutAccessToken[key],
            link,
          };
        }
      });

    preloadedStateCopy.urls = urlsWithoutAccessToken;
  }

  if (preloadedStateCopy.breadcrumbLevels) {
    preloadedStateCopy.breadcrumbLevels = preloadedStateCopy.breadcrumbLevels.map((level) => ({
      ...level,
      href: removeAccessTokenFromPath(level.href),
    }));
  }

  return preloadedStateCopy;
};

const removeSecretToken = (props) => ({
  ...props,
  secretToken: undefined,
});

const removeHeadersFromPreloadedState = (preloadedState) => ({
  ...preloadedState,
  urlState: {
    ...preloadedState.urlState,
    headers: {
      ...preloadedState.urlState.headers,
      'x-api-authentication-v2': undefined,
      'x-forwarded-for': undefined,
      'x-nginx-host': undefined,
      'x-nginx-pool': undefined,
    },
  },
});

const sanitizePreloadedState = (preloadedState) => {
  if (!preloadedState) return preloadedState;
  try {
    let preloadedStateCopy = { ...preloadedState };

    // TODO: Remove this when the app is migrated to Nordic 8
    if (preloadedStateCopy.device && preloadedStateCopy.device.nativeApp) {
      preloadedStateCopy = removeAccessTokenFromPreloadedState(preloadedState);
    }

    if (preloadedState.secretToken) {
      preloadedStateCopy = removeSecretToken(preloadedStateCopy);
    }

    if (preloadedState.urlState?.headers) {
      preloadedStateCopy = removeHeadersFromPreloadedState(preloadedStateCopy);
    }

    return preloadedStateCopy;
  } catch (e) {
    Logger.error('Error sanitizing preloadedState', { stack: e.stack });
    return preloadedState;
  }
};

module.exports = {
  removeAccessTokenFromUrl,
  removeAccessTokenFromPath,
  removeAccessTokenFromPreloadedState,
  removeSecretToken,
  sanitizePreloadedState,
  removeTokensFromString,
};
