/**
 * Module dependencies
 */
const { createLogger } = require('redux-logger');

/**
 * Custom redux logger
 */
const reduxActionsLogger = createLogger({
  collapsed: true,
  logErrors: true,
  titleFormatter: (action, time, took) => `Action: ${String(action.type)} [${time} ${Math.round(took)}ms]`,
  actionTransformer: (action) => {
    if (action.type === 'BATCHING_REDUCER.BATCH') {
      action.payload.type = action.payload.map(next => next.type).join(' => ');

      return action.payload;
    }

    return action;
  },
});

module.exports = reduxActionsLogger;
