/**
 * Module dependencies
 */
const { combineReducers } = require('redux');
const { reducer: base } = require('./base');
const { reducer: restriction } = require('./restriction');
const { reducer: cases } = require('./cases');
const { reducer: profiles } = require('./profiles');
const { reducer: config } = require('./config');
const { reducer: customPortal } = require('./customPortal');
const { reducer: delayCardsData } = require('./delayCardsData');
const { reducer: widgetDetails } = require('./widgetDetails');
const { reducer: predictedContents } = require('./predictedContents');
const { reducer: hasCreditRestriction } = require('./predictedContents');

/**
 * Expose reducers
 */
module.exports = combineReducers({
  base,
  restriction,
  cases,
  profiles,
  config,
  customPortal,
  delayCardsData,
  widgetDetails,
  predictedContents,
  hasCreditRestriction,
});
