const React = require('react');

const I18nContext = React.createContext();

const useI18n = () => {
  const context = React.useContext(I18nContext);
  if (!context) {
    throw new Error('useI18n must be used within a I18nContext Provider');
  }
  return context;
};

module.exports = {
  I18nContext,
  useI18n,
};
