/**
 * Module dependencies
 */
const React = require('react');
const { string, bool, arrayOf, node, oneOfType } = require('prop-types');
const Collapse = require('react-collapse');
const classNames = require('classnames');

const { useState } = React;

const Collapsible = ({ baseClass, title, initOpened, children }) => {
  const [open, setOpen] = useState(initOpened);

  const toggleOpen = (event) => {
    event.preventDefault();
    const keyCode = event.which || event.keyCode;
    if (event.type === 'click' || [32, 13].includes(keyCode)) {
      setOpen(!open);
    }
  };

  return (
    <div className={`widget-collapsible ${baseClass}-collapsible`}>
      <div
        className={`widget-collapsible__title ${baseClass}-collapsible__title`}
        role="presentation"
        onClick={toggleOpen}
        tabIndex={0}
        onKeyPress={toggleOpen}
      >
        {title}
        <div
          className={classNames('widget-collapsible__dropdown-arrow', {
            'widget-collapsible__dropdown-arrow--active': open,
          })}
        />
      </div>
      <Collapse isOpened={open}>{children}</Collapse>
    </div>
  );
};

Collapsible.propTypes = {
  baseClass: string.isRequired,
  title: string.isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  initOpened: bool,
};
Collapsible.defaultProps = {
  initOpened: false,
};

module.exports = Collapsible;
