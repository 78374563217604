/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const SearchInput = require('../../../InputSearch');

const Search = ({ siteId }) => (
  <div className="cx-peach__widget--search">
    <SearchInput siteId={siteId} />
  </div>
);

Search.propTypes = {
  siteId: string.isRequired,
};

Search.defaultProps = {
};

module.exports = injectI18n(Search);
