/* eslint-disable max-len */
const React = require('react');
const { bool, string } = require('prop-types');
const { useI18n } = require('../../utils/context/i18nContext');
const InputSearchV2 = require('../InputSearchV2');
const { usePlatformState } = require('../../utils/context/platform');
const { useDeviceState } = require('../../utils/context/device');
const { useTrackVisibility } = require('react-intersection-observer-hook');
const { executeNative } = require('../../utils/mobile/nativeActions');
const SearchSectionML = require('../../assets/ilustrations/SearchSectionML');
const SearchSectionMP = require('../../assets/ilustrations/SearchSectionMP');
const { CxSearchVisibility } = require('../../utils/mobile/constants');
const { useSearchState } = require('../../utils/context/search');
const { useSelector } = require('react-redux');
const { selectConfig } = require('../../redux/selectors/config');
const { useUrlState } = require('../../utils/context/url');

const SearchSectionV2 = () => {
  const { focus: isFocused } = useSearchState();
  const i18n = useI18n();
  const { siteId, id: business } = usePlatformState();
  const [ref, { isVisible }] = useTrackVisibility();
  const { nativeApp } = useDeviceState();
  const { isSearchButtonV2Enabled } = useSelector(selectConfig);
  const { headers = {} } = useUrlState();
  const isYoshi = headers['x-webkit-custom'];

  React.useEffect(() => {
    if (nativeApp && !isFocused && isYoshi) {
      executeNative(CxSearchVisibility, {visible: !isVisible});
    }
  },
  [isVisible, nativeApp, isFocused, isYoshi]);

  const SearchSectionComponent = business === 'ML' ? SearchSectionML : SearchSectionMP;
  return (
    <div ref={ref} className="cx-search-section-v2">
      <div className="container">
        <div className="search-box">
          <h1 className="heading">
            {isSearchButtonV2Enabled ? i18n.gettext('¿Con qué te ayudamos?') : i18n.gettext('¿Con qué podemos ayudarte?')}
          </h1>
          <InputSearchV2 siteId={siteId} />
        </div>
        <div className="image" aria-hidden="true">
          <SearchSectionComponent />
        </div>
      </div>
    </div>
  );
};

SearchSectionV2.propTypes = {
  searchEnabled: bool,
  title: string,
};

SearchSectionV2.defaultProps = {
  searchEnabled: true,
  title: undefined,
};

module.exports = SearchSectionV2;
