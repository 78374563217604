/**
 * Module dependencies
 */
const React = require('react');
const { string, oneOfType, arrayOf, node } = require('prop-types');
const classNames = require('classnames');

const PaymentIcon = require('../../../../PaymentIcon');

const StatusCard = ({ icon, statusIcon, statusLabel, labelStatus, title, children }) => (
  <div className={classNames('status-card', { 'status-card--only-status': !title && !children })}>
    <div className="status-card__icon-wrapper">
      <PaymentIcon name={icon} status={statusIcon} />
    </div>

    <div className="status-card__detail">
      <p className={`status-card__label-status status-card__label-status--${statusLabel || statusIcon}`} data-testid="title">
        {labelStatus}
      </p>
      {title && <p className="status-card__title" data-testid="title">{title}</p>}
      {children && <div className="status-card__subtitle" data-testid="subtitle">{children}</div>}
    </div>
  </div>
);

StatusCard.propTypes = {
  icon: string,
  statusIcon: string,
  statusLabel: string,
  labelStatus: string,
  title: string,
  children: oneOfType([node, arrayOf(node)]),
};
StatusCard.defaultProps = {
  icon: '',
  statusIcon: '',
  statusLabel: '',
  labelStatus: '',
  title: '',
  children: null,
};

module.exports = StatusCard;
