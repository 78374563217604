const React = require('react');
const classNames = require('classnames');
const Card = require('@andes/card');

const { getSuggestions } = require('../../browserServices/search');
const { useSuggestion } = require('../../utils/context/search');
const { useI18n } = require('../../utils/context/i18nContext');
const { useUrlState } = require('../../utils/context/url');
const { useDeviceState } = require('../../utils/context/device');
const { usePlatformState } = require('../../utils/context/platform');
const { useSearchDispatch } = require('../../utils/context/search');
const { createDeeplink, setupAndroidToolbarMP, removeView } = require('../../utils/mobile/nativeActions');
const { useFlags } = require('../../utils/context/flags');
const { cleanString } = require('../../../api/search/utils');
const { executeNative } = require('../../utils/mobile/nativeActions');
const { NativeClearSearchEvent, MercadoPago } = require('../../utils/mobile/constants');
const { isSearchResultView, isAndroid } = require('../../utils');
const { BUSINESS_UNITS, OPTION_TYPES } = require('../../utils/constants');


const SuggestionSearch = ({ bu, isSearchButtonV2Enabled = false, showNativeSearch = false, actualLocation = '' }) => {
  const i18n = useI18n();
  const { baseUrl } = useUrlState();
  const { webView, nativeApp } = useDeviceState();
  const { 'redesign-ui-suggestion-redirect-to-search': isFFSuggestionRedirectToSearchOn } = useFlags();
  const dispatch = useSearchDispatch();
  const platform = usePlatformState();
  const {
    value,
    isOpen,
    containerStyle,
    selectedIndex,
    options,
    selectOption,
  } = useSuggestion({
    baseUrl,
    listenMobileEvents: webView,
    fullScreen: webView,
    dataSource: getSuggestions,
    limit: webView ? 10 : 6,
    isSearchButtonV2Enabled,
    showNativeSearch,
    actualLocation,
  });

  const suggetsForSearchText = i18n.gettext('Sugerencias para tu búsqueda');
  const suggetsForSearchAltText = i18n.gettext('Tus últimas búsquedas');

  const isNativeMP = nativeApp && nativeApp.name?.toLowerCase() === MercadoPago;

  const title = value
    ? suggetsForSearchText
    : suggetsForSearchAltText;

  const shouldClearSearchNative = isSearchButtonV2Enabled || showNativeSearch;

  const hasHistoryItems = () => Array.isArray(options) && options.some(option => option && option.type === OPTION_TYPES.HISTORY);

  const mustShowEmptyCard = () => bu === BUSINESS_UNITS.ML && !hasHistoryItems();

  const showEmptyCard = mustShowEmptyCard();

  const clearAndroidToolBar = () => {
    if (isNativeMP) {
      setupAndroidToolbarMP();
    }
    executeNative(NativeClearSearchEvent, {});
  };

  const handleClickRedirectToSearch = (event, option) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    const { title } = option;
    const { id, domain } = platform;
    const queryWithoutSpecialChars = cleanString(title);
    const querySearchWithoutSpaces = queryWithoutSpecialChars.replace(/ /g, '+');
    let link = `${baseUrl}/search?q=${querySearchWithoutSpaces}`;

    if (nativeApp) {
      link = createDeeplink(link, `https://www.${domain}`, id);
      if (shouldClearSearchNative && isAndroid(nativeApp)) {
        clearAndroidToolBar();
      }
      if (shouldClearSearchNative && isSearchResultView(actualLocation)) {
        removeView();
      }
    }

    window.location.assign(link);
  };

  const handleClick = (event) => {
    event.preventDefault();
  };

  const handleClickRedirectToFAQ = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    const { href } = option;
    const { id, domain } = platform;
    const linkUrl = nativeApp ? createDeeplink(href.replace('#suggest', ''), `https://www.${domain}`, id) : href;
    window.location.assign(linkUrl);
  };

  const handleFill = (event, data) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    dispatch({ type: 'setSearchValue', data });
  };

  const handleOnMouseDown = isFFSuggestionRedirectToSearchOn ? handleClickRedirectToSearch : handleClickRedirectToFAQ;

  if (!isOpen) return null;

  return (
    <div className="suggestion-search" style={containerStyle} aria-live="assertive" aria-atomic="true">
      <Card shadow="elevated" className="suggestion-search__container">
        {!showEmptyCard && (
          <>
            <h5 className="suggestion-search__title" id="suggestion-search__title">{title}</h5>
            <ul role="listbox" className="suggestion-search__list" id="cb2-listbox" aria-labelledby="suggestion-search__title">
              { options.map((option, index) => (
                <li
                  role="presentation"
                  key={option.key}
                  className="suggestion-search__item"
                >
                  <a
                    tabIndex="-1"
                    role="option"
                    aria-selected={selectedIndex === index}
                    onMouseDown={(event) => handleOnMouseDown(event, option)}
                    onMouseOver={() => selectOption(index)}
                    onFocus={() => selectOption(index)}
                    {...(isFFSuggestionRedirectToSearchOn ? { onClick: handleClick } : {})}
                    className={classNames('suggestion-search__link', {
                      'suggestion-search__link--selected': selectedIndex === index,
                      'suggestion-search__link--visited': option.type === 'history',
                    })}
                    id={`suggestion-search__link--selected-${index}`}
                    href={option.href}
                  >
                    { option.highlightedText }
                    { !isFFSuggestionRedirectToSearchOn && <span
                      className="suggestion-search__fill"
                      onMouseDown={(event) => handleFill(event, option.title)}
                      role="presentation"
                      data-testid="suggestion-fill"
                    >
                      <i className="suggestion-search__fill-icon" />
                    </span>}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </Card>
    </div>
  );
};

module.exports = SuggestionSearch;
