module.exports = {
  SOURCE_ID_MP: '1972',
  SOURCE_ID_ML: '2023',
  BUSINESS_ML: 'ML',
  BUSINESS_MP: 'MP',
  EXPANDED_LIMIT: 8,
  STATUS_ACTIVE: 'active',
  STATUS_INACTIVE: 'inactive',
  STATUS_CONNECTED: 'connected',
  NOT_FOUND: 'not_found',
  TIME_NEW_REQUEST: 15000,
  ORIGIN_RECONTACT: 'Floating Button',
  CLASS_NOT_CONTACT_IN_PROGRESS: 'contact-icon',
  CLASS_CONTACT_CALL_IN_PROGRESS: 'contact-c2c-in-progress-icon',
  CLASS_CONTACT_CHAT_MELICHAT_PROGRESS: 'contact-chat-in-progress-icon',
  CLASS_CONTACT_CHAT_WHATSAPP_PROGRESS: 'contact-whatsapp-in-progress-icon',
  URL_BASE: '/floating_button/contact_in_progress',
  ANDES_CLASS_BUTTON: 'andes-button--large andes-button--loud',
  BASE_CLASS_NAME: 'floating-button__button',
  EXPANDED_CLASS_NAME: '--expanded',
  MOBILE_CLASS_NAME: '--mobile',
};
