const constants = require('../constants');
const semver = require('semver');

const ANDROID = 'android';
const IOS = 'ios';

const isWebkit2 = () => (typeof window !== 'undefined' && window.MobileWebKit && window.MobileWebKit.Engines && window.MobileWebKit.engine === window.MobileWebKit.Engines.V200);

const isValidVersion = (device) => {
  if (!device.nativeApp) {
    return false;
  }

  const { os, name, version } = device.nativeApp;
  const osName = os?.toLowerCase();
  const appName = name?.toLowerCase();
  const isMobile = (osName === ANDROID) || (osName === IOS);

  const appVersions = {
    mercadolibre: '10.258.0',
    mercadopago: '2.268.0',
  };

  const isValidVersion = isMobile && appVersions[appName] && semver.gte(version, appVersions[appName]);
  return isValidVersion;
};

const executeShareEvent = (method, args) => {
  if (isWebkit2()) {
    AddShareTrack(args);
    window.MobileWebKit.executeNative({
      method,
      args: { url: args.url, title: args.title },
    });
  }
};

const executeNative = (method, args) => (isWebkit2() ? window.MobileWebKit.executeNative({method, args}) : '');

const AddShareTrack = (args) => {
  if (typeof window !== 'undefined' && window.melidata) {
    const melidataTrackData = {
      path: '/portal/faq/share',
      event_data: {
        portal_content_destination_url: args.url,
        portal_content_transactional_data: args.title,
        portal_has_channels_configured: args.portal_has_channels_configured,
        portal_content_id: args.portal_content_id,
      },
    };
    window.melidata('cleanAndSend', 'event', melidataTrackData);
  }
};

const isDeeplink = (url) => {
  const validProtocols = [...constants.DEEPLINK_INTERNAL_PROTOCOLS, ...constants.DEEPLINK_EXTERNAL_PROTOCOLS];
  return validProtocols.some((protocol) => url?.startsWith(protocol));
};

const createDeeplinkFromURL = (url, homeUrl, id) => {
  const baseDeeplink = getBaseDeeplink(id);
  const absoluteURL = url?.startsWith('/') ? `${homeUrl}${url}` : url;
  return `${baseDeeplink}${encodeURIComponent(absoluteURL)}`;
};

const createDeeplink = (url, homeUrl, id) => (isDeeplink(url) ? url : createDeeplinkFromURL(url, homeUrl, id));

const getBaseDeeplink = (business) => {
  const isML = constants.MLBUS.includes(business);
  const baseDeeplink = isML ? constants.MLHelpBaseDeeplink : constants.MPHelpBaseDeeplink;
  return baseDeeplink;
};

const setupAndroidToolbarMP = () => {
  if (typeof window !== 'undefined' && window.MobileWebKit) {
    window.MobileWebKit?.topbar?.setup({ bar_left_button_style: constants.BUTTON_STYLE_NONE });
    window.MobileWebKit?.topbar?.setup({ bar_left_button_style: constants.BUTTON_STYLE_BACK });
    window.MobileWebKit?.topbar?.setup({ bar_content_color: constants.BUTTON_WHITE_COLOR });
  }
};

const removeView = () => {
  if (isWebkit2()) {
    window.MobileWebKit?.navigation?.pop();
  }
};


module.exports = {
  isWebkit2,
  isValidVersion,
  executeShareEvent,
  createDeeplink,
  executeNative,
  getBaseDeeplink,
  isDeeplink,
  setupAndroidToolbarMP,
  removeView,
};
