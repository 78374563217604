/**
 * Module dependencies
 */
const { createStore, applyMiddleware } = require('redux');
const logger = require('./logger');
const thunk = require('redux-thunk').default;
const reducers = require('../reducers');

// Redux middlewares
const middlewares = () => {
  const result = [thunk];

  if (process.env.NODE_ENV === 'development') {
    result.push(logger);
  }

  return result;
};

module.exports = (initialStore = {}) => createStore(reducers, initialStore, applyMiddleware(...middlewares()));
