/**
 * Module dependencies
 */
/* istanbul ignore file */
const React = require('react');
const { useState, useRef,
  useCallback,
  useEffect,
} = require('react');

const { string, bool } = require('prop-types');
const { useUrlState } = require('../../utils/context/url');
const SITEIDS = require('../../utils/siteIds');
const { useChatDispatch } = require('../../utils/context/chatProvider');

const ContactInProgressButton = require('./ContactInProgressButton');

const { getOngoingChat } = require('../../browserServices/ongoingChatV3');
const { getOngoingCall } = require('../../browserServices/ongoingCallV3');

const { STATUS_ACTIVE, STATUS_CONNECTED, ORIGIN_RECONTACT, URL_BASE, SOURCE_ID_ML, SOURCE_ID_MP } = require('./constants');
const CHANNEL_TYPES = require('./channels');
const { useI18n } = require('../../utils/context/i18nContext');
const { useDeviceState } = require('../../utils/context/device');
const { usePlatformState } = require('../../utils/context/platform');
const { useInvokeActionOnUserInteraction } = require('../../hooks/useInvokeActionOnUserInteraction');
const { useFlags } = require('../../utils/context/flags');

const ContactInProgress = (props) => {
  const {
    onClick,
    whatsAppConfiguration,
  } = props;

  const i18n = useI18n();
  const [link, setLink] = useState();
  const [channel, setChannel] = useState(CHANNEL_TYPES.none);
  const { homeUrl, baseUrl } = useUrlState();
  const chatDispatch = useChatDispatch();
  const { nativeApp } = useDeviceState();
  const {id: bu, siteId} = usePlatformState();
  const { 'is-fb-v3-calls-triggered-by-events': isInvokeRequestsOnUserActivityOn, floating_button_v4_enable_on_home: isV4On } = useFlags();

  const melidata = useRef({ chatId: '', callId: '', caseId: '', message: '' });

  const resetState = () => {
    setChannel(CHANNEL_TYPES.none);
    setLink(undefined);
  };

  const getWhatsAppUrl = () => {
    let whatsAppPhone = whatsAppConfiguration.hspStack.phoneNumber;
    if (siteId === SITEIDS.mlb) {
      whatsAppPhone = whatsAppConfiguration.brStack.phoneNumber;
    }
    const whatsAppLink = `${whatsAppConfiguration.baseURL}${whatsAppPhone}?text=${i18n.gettext('Hola')}`;

    return whatsAppLink;
  };

  const fetchCallContactInProgress = useCallback(() => getOngoingCall()
    .then(({ data }) => {
      if (data.success && (data.status.toLowerCase() === STATUS_ACTIVE || data.status.toLowerCase() === STATUS_CONNECTED)) {
        return { hasActiveCall: true, ongoingCallUrl: data.ongoingCallUrl, callId: data.call_id, caseId: data.case_id };
      }

      return { hasActiveCall: false };
    }), []);

  const fetchChatContactInProgress = useCallback(() => getOngoingChat()
    .then(({ data }) => {
      if (data.success && data.chats.length > 0) {
        let chatLink;
        const firstChat = data.chats[0];

        if (firstChat.provider === CHANNEL_TYPES.melichat) {
          chatLink = addSourceIdParam(`${homeUrl}${baseUrl}/chat/v2?chat_id=${firstChat.chat_id}&case_id=${firstChat.case_id}`);
        } else {
          chatLink = getWhatsAppUrl();
        }


        return { hasActiveChat: true, chatId: firstChat.chat_id, caseId: firstChat.case_id, ongoingChatUrl: chatLink, chatProvider: firstChat.provider };
      }

      return { hasActiveChat: false };
    }), [i18n]);

  const fetchContactInProgress = useCallback(async () => {
    const { hasActiveCall, callId, caseId, ongoingCallUrl } = await fetchCallContactInProgress();

    if (hasActiveCall) {
      melidata.current.callId = callId;
      melidata.current.caseId = caseId;
      setChannel(CHANNEL_TYPES.call);
      setLink(ongoingCallUrl);
      return;
    }

    const { hasActiveChat, chatId, caseId: chatCaseId, ongoingChatUrl, chatProvider } = await fetchChatContactInProgress();

    if (hasActiveChat) {
      melidata.current.chatId = chatId;
      melidata.current.caseId = chatCaseId;
      setChannel(chatProvider);
      setLink(ongoingChatUrl);
      return;
    }

    resetState();
  }, [fetchChatContactInProgress, fetchCallContactInProgress]);

  useEffect(() => {
    if (isV4On) {
      return;
    }
    fetchContactInProgress();
  }, [fetchContactInProgress]);

  useInvokeActionOnUserInteraction({
    action: fetchContactInProgress,
    cooldown: 20000,
    isInitialInvokeDone: true,
    isMobile: Boolean(nativeApp),
    invokeActionOnUserActivity: isInvokeRequestsOnUserActivityOn,
  });

  const addSourceIdParam = (url) => {
    const source_id = (bu === 'MP' ? SOURCE_ID_MP : SOURCE_ID_ML);
    if (!url.includes('sourceId')) { return `${url}&sourceId=${source_id}`; }
    return url;
  };


  const createDeepLink = (link) => {
    const mobileScheme = bu === 'ML' ? 'meli' : 'mercadopago';
    const encodedLink = encodeURIComponent(link);
    const deeplink = `${mobileScheme}://cx/help?url=${encodedLink}`;
    return deeplink;
  };

  const handleClick = (event) => {
    event.preventDefault();

    sendMelidataTrack(channel);


    switch (channel) {
      case CHANNEL_TYPES.call:
        window.location.assign(link);
        return;
      case CHANNEL_TYPES.whatsapp: {
        if (nativeApp) {
          window.location.assign(link);
          return;
        }
        const newChat = window.open(link, 'chatTab');
        newChat.name = 'chatTab';
        chatDispatch({ type: 'setChatReference', chatReference: newChat });
        return;
      }
      case CHANNEL_TYPES.melichat: {
        if (nativeApp) {
          const deeplink = createDeepLink(link);
          window.location.assign(deeplink);
          return;
        }
        const newChat = window.open(link, 'chatTab');
        newChat.name = 'chatTab';
        chatDispatch({ type: 'setChatReference', chatReference: newChat });
        return;
      }
      case CHANNEL_TYPES.none:
      default:
        onClick(event);
    }
  };

  const sendMelidataTrack = (activeChannel) => {
    if (!window?.melidata) return;

    const contactData = {
      origin_recontact: ORIGIN_RECONTACT,
      message: activeChannel === CHANNEL_TYPES.none ? 'Sin contacto en curso' : `Se encuentra un contacto en curso de tipo ${activeChannel}`,
      portal_source_id: Number(bu === 'MP' ? SOURCE_ID_MP : SOURCE_ID_ML),
      case_id: Number(melidata.current.caseId),
    };

    let eventData;

    switch (activeChannel) {
      case CHANNEL_TYPES.call:
        eventData = { path: `${URL_BASE}/call`, event_data: {...contactData, call_id: String(melidata.current.callId)}};
        break;
      case CHANNEL_TYPES.melichat:
      case CHANNEL_TYPES.whatsapp:
        eventData = { path: `${URL_BASE}/chat`, event_data: {...contactData, chat_id: String(melidata.current.chatId)}};
        break;
      case CHANNEL_TYPES.none:
      default:
        eventData = { path: `${URL_BASE}`, event_data: {...contactData}};
        break;
    }

    window.melidata('cleanAndSend', 'event', eventData);
  };

  return (
    <ContactInProgressButton
      channel={channel}
      handleClick={handleClick}
      bu={bu}
      isMobile={Boolean(nativeApp)}
    />
  );
};

ContactInProgress.propTypes = {
  bu: string.isRequired,
  isMobile: bool,
};

module.exports = ContactInProgress;

