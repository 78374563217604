/**
 * Module dependencies
 */
const React = require('react');
const { string, objectOf, any } = require('prop-types');

const Summary = require('./Summary');

const SingleCard = ({ status, label, product }) => (
  <div className="product-card">
    <p className={`product-card__label-status product-card__label-status--${status}`}>{label}</p>

    <ul className="product-card__list">
      <Summary data={product} />
    </ul>
  </div>
);

SingleCard.propTypes = {
  status: string.isRequired,
  label: string.isRequired,
  product: objectOf(any).isRequired,
};
SingleCard.defaultProps = {};

module.exports = SingleCard;
