/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const Cash = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="cash-a" cx="24" cy="24" r="24" />
      <rect id="cash-c" width="16" height="16" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="cash-b" fill="#fff">
          <use xlinkHref="#cash-a" />
        </mask>
        <use fill="#FFF" xlinkHref="#cash-a" />
        <g fill="#3483FA" fillRule="nonzero" mask="url(#cash-b)">
          <path d="M24.75 20.132c.763.14 1.417.458 1.943.944l-.87 1.144c-.593-.55-1.385-.803-2.133-.803-.671 0-1.045.297-1.045.737 0 .473.67.627 1.463.814 1.21.286 2.75.649 2.75 2.31 0 1.139-.69 2.04-2.108 2.287v.935h-1.5v-.906c-1.065-.126-1.863-.55-2.431-1.128l.847-1.188c.517.539 1.32.99 2.332.99.858 0 1.276-.396 1.276-.814 0-.539-.682-.693-1.485-.891-1.2-.275-2.74-.605-2.74-2.277 0-1.077.822-1.991 2.201-2.192v-.791h1.5v.83zm-12-3.638h22.5v15.012h-22.5V16.494zm1.5 1.5v12.012h19.5V17.994h-19.5zM30.415 25.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-12.73 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
        </g>
      </g>
      <g transform="translate(34 34)">
        <mask id="cash-d" fill="#fff">
          <use xlinkHref="#cash-c" />
        </mask>
        {status === 'success' && (
          <g mask="url(#cash-d)">
            <path fill="#00A650" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.788 9.396l4.577-4.577 1.028 1.029-5.605 5.605-3.181-3.181 1.028-1.029z"
            />
          </g>
        )}
        {status === 'warning' && (
          <g mask="url(#cash-d)">
            <path fill="#F73" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
        {status === 'error' && (
          <g mask="url(#cash-d)">
            <path fill="#F23D4F" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
      </g>
    </g>
  </svg>
);

Cash.propTypes = {
  status: string,
};
Cash.defaultProps = {
  status: '',
};

module.exports = Cash;
