const defaultBasePath = '/';
const defaultApiBasePath = '/help/api';

class EnvPageService {
  constructor() {
    this._basePath = defaultBasePath;
    this._apiBasePath = defaultApiBasePath;
  }

  setParams({ basePath = this._basePath, apiBasePath = this._apiBasePath }) {
    this._basePath = basePath;
    this._apiBasePath = apiBasePath;
  }

  get basePath() {
    return this._basePath;
  }

  get apiBasePath() {
    return this._apiBasePath;
  }
}

const envPageService = new EnvPageService();

module.exports = envPageService;
