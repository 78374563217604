module.exports = {
  Search: {
    SubscribeEvent: {
      Change: 'native_search_change',
      Focus: 'native_search_focus',
      Blur: 'native_search_blur',
    },
  },
  NativeClearSearchEvent: 'native_clear_search_view',
  CxSearchVisibility: 'cx_search_visibility',
  Android: 'android',
  SmartPOS: 'smartpos',
  MercadoPago: 'mercadopago',
};
