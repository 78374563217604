/**
 * Module dependencies
 */
const React = require('react');
const { shape, func } = require('prop-types');
const { connect } = require('react-redux');
const injectI18n = require('nordic/i18n/injectI18n');
const classNames = require('classnames');

const Title = require('../components/Title');
const Collapsible = require('../components/Collapsible');
const ChargesBill = require('../Charges/Bill');
const PackageDetail = require('../Purchase/PackageDetail');
const ShippingDetails = require('../Purchase/ShippingDetails');

const Commissions = ({ i18n, header, shipping, item, bill }) => {
  const baseClass = 'widget-commissions';

  return (
    <div className={classNames([baseClass, 'widget-purchase'])}>
      <Title {...header} />

      <PackageDetail
        pack={{
          title: (shipping && shipping.label) || '',
          status: (shipping && shipping.status) || '',
          items: [item],
        }}
      />

      {shipping && <ShippingDetails {...shipping} />}

      {bill && (
        <Collapsible title={i18n.gettext('Cobro')} baseClass={baseClass}>
          <ChargesBill {...bill} />
        </Collapsible>
      )}
    </div>
  );
};

Commissions.propTypes = {
  i18n: shape({ gettext: func.isRequired }).isRequired,
};
Commissions.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { commissions } }) => ({
  ...commissions,
});

const ConectedCommissions = injectI18n(
  connect(
    mapStateToProps,
    {},
  )(Commissions),
);

module.exports = ConectedCommissions;
