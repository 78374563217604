/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any } = require('prop-types');
const { connect } = require('react-redux');
const classNames = require('classnames');

const Title = require('../components/Title');
const PurchasePackageDetail = require('../Purchase/PackageDetail');
const PurchaseShippingDetails = require('../Purchase/ShippingDetails');

const Pack = ({ header, shipping, pack }) => {
  const baseClass = 'widget-pack';

  return (
    <div className={classNames([baseClass, 'widget-purchase'])}>
      <Title {...header} />

      <PurchasePackageDetail pack={pack} packsCount={1} index={0} />

      {shipping && <PurchaseShippingDetails {...shipping} />}
    </div>
  );
};

Pack.propTypes = {
  header: objectOf(any),
  shipping: objectOf(any),
  pack: objectOf(any),
};
Pack.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { pack } }) => ({
  ...pack,
});

const ConectedPack = connect(
  mapStateToProps,
  {},
)(Pack);

module.exports = ConectedPack;
