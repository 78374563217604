/* eslint-disable react/no-unknown-property */
/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const PayPal = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="prefix__a" cx="24" cy="24" r="24" />
      <rect id="prefix__b" width="16" height="16" x="0" y="0" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#prefix__a" />
      { status === 'success'
        && <g transform="translate(34 34)">
          <mask id="prefix__c" fill="#fff">
            <use href="#prefix__b" />
          </mask>
          <g mask="url(#prefix__c)">
            <g>
              <path fill="#00A650" d="M0 0H17.829V16.914H0z" transform="translate(-1 -1) translate(.086 .543)" />
              <path fill="#FFF" fillRule="nonzero" d="M6.788 9.396L11.365 4.819 12.393 5.848 6.788 11.453 3.607 8.272 4.635 7.243z" transform="translate(-1 -1) translate(1 1)" />
            </g>
          </g>
        </g>}
      { status === 'rejected'
        && <g transform="translate(34 34)">
          <mask id="prefix__c" fill="#fff">
            <use href="#prefix__b" />
          </mask>
          <g mask="url(#prefix__c)">
            <g>
              <path fill="#F23D4F" d="M0 0H17.829V16.914H0z" transform="translate(-1 -1) translate(.086 .543)" />
              <path fill="#FFF" fillRule="nonzero" d="M9 11.182c.536 0 .97.434.97.97 0 .535-.434.97-.97.97s-.97-.435-.97-.97c0-.536.434-.97.97-.97zm.97-6.303l-.243 5.333H8.273L8.03 4.88h1.94z" transform="translate(-1 -1)" />
            </g>
          </g>
        </g>}
      { status === 'in_process'
      && <g transform="translate(34 34)">
        <mask id="prefix__c" fill="#fff">
          <use xlinkHref="#prefix__b" />
        </mask>
        <g mask="url(#prefix__c)">
          <g>
            <path fill="#F73" d="M0 0H17.829V16.914H0z" transform="translate(-1 -1) translate(.086 .543)" />
            <path fill="#FFF" fillRule="nonzero" d="M9 11.182c.536 0 .97.434.97.97 0 .535-.434.97-.97.97s-.97-.435-.97-.97c0-.536.434-.97.97-.97zm.97-6.303l-.243 5.333H8.273L8.03 4.88h1.94z" transform="translate(-1 -1)" />
          </g>
        </g>
      </g>}
      <g>
        <g fillRule="nonzero">
          <path fill="#003087" d="M15.255 1.594C14.275.478 12.506 0 10.242 0h-6.57c-.463 0-.857.336-.93.794L.008 18.146c-.026.162.02.328.128.454.107.125.264.197.429.197H4.62l1.019-6.462-.032.203c.071-.457.464-.793.926-.794h1.928c3.786 0 6.751-1.538 7.617-5.987.026-.132.048-.26.068-.385.257-1.644-.002-2.764-.892-3.777" transform="translate(9 9) translate(8 6)" />
          <path fill="#003087" d="M15.255 1.594C14.275.478 12.506 0 10.242 0h-6.57c-.463 0-.857.336-.93.794L.008 18.146c-.026.162.02.328.128.454.107.125.264.197.429.197H4.62l1.019-6.462-.032.203c.071-.457.464-.793.926-.794h1.928c3.786 0 6.751-1.538 7.617-5.987.026-.132.048-.26.068-.385.257-1.644-.002-2.764-.892-3.777" transform="translate(9 9) translate(8 6)" />
          <path fill="#003087" d="M6.734 5.394c.064-.4.408-.694.812-.694h5.151c.61 0 1.18.04 1.7.122.284.046.565.109.841.189.315.088.619.21.907.361.259-1.645 0-2.764-.89-3.778C14.275.48 12.506.001 10.242.001h-6.57c-.463 0-.857.336-.93.793L.008 18.144c-.026.163.02.33.128.455.107.125.264.197.429.197H4.62l1.018-6.462 1.095-6.94z" transform="translate(9 9) translate(8 6)" />
          <path fill="#009CDE" d="M16.146 5.372c-.02.128-.042.256-.067.384-.866 4.449-3.831 5.988-7.618 5.988H6.533c-.462 0-.855.337-.925.793l-.987 6.259-.281 1.775c-.023.143.018.288.112.398.094.11.231.173.376.173h3.419c.405 0 .749-.295.812-.694l.034-.174.644-4.085.041-.225c.063-.4.408-.695.813-.695h.512c3.312 0 5.906-1.345 6.664-5.237.315-1.626.152-2.984-.685-3.938-.265-.296-.583-.54-.936-.722" transform="translate(9 9) translate(8 6)" />
          <path fill="#012169" d="M15.239 5.01c-.132-.039-.268-.074-.409-.105-.14-.03-.285-.058-.434-.081-.52-.085-1.089-.125-1.7-.125h-5.15c-.405 0-.75.294-.812.695L5.64 12.335l-.031.202c.07-.456.463-.793.925-.793H8.46c3.787 0 6.752-1.538 7.618-5.988.026-.131.047-.259.067-.384-.229-.12-.467-.22-.712-.301-.064-.021-.129-.041-.195-.06" transform="translate(9 9) translate(8 6)" />
        </g>
        <path d="M0 0H32V32H0z" transform="translate(9 9)" />
      </g>
    </g>
  </svg>
);

PayPal.propTypes = {
  status: string,
};
PayPal.defaultProps = {
  status: '',
};

module.exports = PayPal;
