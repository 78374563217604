/**
 * Module dependencies
 */
const React = require('react');
const Button = require('@andes/button');
const { string, shape, func } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const TextField = require('@andes/textfield');

const Icon = require('../Icon');
const { useUrlState } = require('../../utils/context/url');
const { usePlatformState } = require('../../utils/context/platform');
const { useDeviceState } = require('../../utils/context/device');
const { useSearchState, useSearchDispatch } = require('../../utils/context/search');
const { useFlags } = require('../../utils/context/flags');

const ENTER_KEYCODE = 13;

const { useEffect, useRef } = React;
/**
 * InputSearch Component
 */
const normalizeArrowKey = (event) => {
  const { key, keyCode } = event;
  /* istanbul ignore next (ie) */
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0) {
    return `Arrow${key}`;
  }
  return key;
};

const InputSearch = ({ i18n, searchedText }) => {
  const { value: searchValue, mask, selectedIndex, options, focus } = useSearchState();
  const dispatch = useSearchDispatch();
  const { baseUrl } = useUrlState();
  const { type: deviceType } = useDeviceState();
  const { id: business } = usePlatformState();
  const { 'new-search': newSearch, 'new-search-mp': newSearchMP } = useFlags();
  const showNewSearch = ['ML', 'MS'].includes(business) ? newSearch : newSearchMP;
  const mpText = i18n.pgettext('MP', 'Buscar en Ayuda');
  const mlText = i18n.pgettext('ML', 'Buscar en Ayuda');
  const searchText = i18n.gettext('Buscar');

  const inputRef = useRef();

  useEffect(() => {
    if (searchedText) {
      dispatch({ type: 'setSearchValue', data: searchedText });
    }
  }, [searchedText, dispatch]);

  const submit = (event) => {
    event.preventDefault();
    const highlightedOption = options[selectedIndex];

    if (highlightedOption) {
      window.location.assign(highlightedOption.href);
    } else {
      window.location.assign(`${baseUrl}/search?q=${searchValue}`);
    }
  };

  const handleChange = ({ target: { value } }) => {
    dispatch({ type: 'setSearchValue', data: value });
  };

  const handleFocus = (isFocused) => {
    if (!showNewSearch) return;
    const { width, height, top, left } = inputRef.current.getBoundingClientRect();
    const { scrollY } = window;
    const marginTop = 12;
    const topPosition = top + height + scrollY + marginTop;
    dispatch({ type: 'setSearchPosition', data: { width, top: topPosition, left } });
    dispatch({ type: 'setSearchFocus', data: isFocused });
    if (isFocused && deviceType !== 'desktop') {
      window.scrollTo(0, top + scrollY);
    }
  };

  const handleClear = () => {
    dispatch({ type: 'setSearchValue', data: '' });
  };

  const onEnterPressed = (event) => {
    const {target} = event;
    if (target.name === 'clearSearch') return;

    const keyCode = event.which || event.keyCode;
    if (keyCode === ENTER_KEYCODE) {
      submit(event);
    }
    const key = normalizeArrowKey(event);

    if (key === 'ArrowDown') {
      dispatch({ type: 'setNextOption' });
    }

    if (key === 'ArrowUp') {
      dispatch({ type: 'setPrevOption' });
    }

    if (!focus && key !== 'Escape') {
      handleFocus(true);
    }

    if (key === 'Escape') {
      handleFocus(false);
    }
  };

  const clearButton = (showNewSearch) && searchValue
    ? (
      <button
        tabIndex={0}
        className={`cx-search__cancel-button ${(deviceType === 'desktop') ? 'cx-search__cancel-button_desktop' : ''}`}
        aria-label={i18n.gettext('Limpiar búsqueda')}
        onClick={handleClear}
        name="clearSearch"
      >
        <Icon type="times" className="cx-search__cancel-icon" />
      </button>
    ) : null;

  const button = (showNewSearch)
    ? (
      <Button disabled={!(mask || searchValue)} className="cx-search-button" hierarchy="quiet" onClick={submit}>
        {searchText}
      </Button>
    ) : null;


  const newPlaceholder = (business === 'MP' || baseUrl.includes('envios-extra'))
    ? mpText
    : mlText;

  const placeholder = showNewSearch ? newPlaceholder : searchText;

  return (
    <div
      ref={inputRef}
      className={(showNewSearch && deviceType === 'desktop') ? 'cx-search-box' : 'search-box'}
    >
      <TextField
        role="combobox"
        id="cb1-listbox"
        className="cx-input-search"
        placeholder={placeholder}
        value={mask || searchValue}
        onKeyDown={onEnterPressed}
        onChange={handleChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        aria-owns="cb1-listbox"
        aria-activedescendant={selectedIndex !== undefined ? `suggestion-search__link--selected-${selectedIndex}` : ''}
        aria-controls="cb2-listbox"
        aria-expanded={options.length > 0}
        suffix={clearButton}
        srLabel={placeholder}
      />
      {button}
    </div>
  );
};

InputSearch.propTypes = {
  i18n: shape({ gettext: func.isRequired }).isRequired,
  searchedText: string,
};

InputSearch.defaultProps = {
  searchedText: '',
};

module.exports = injectI18n(InputSearch);
