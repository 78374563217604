/**
 * Module dependencies
 */
const React = require('react');
const { string, shape, func } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const SearchBox = require('@andes/search-box');
const { useUrlState } = require('../../utils/context/url');
const { useDeviceState } = require('../../utils/context/device');
const { useI18n } = require('../../utils/context/i18nContext');
const { useSearchState, useSearchDispatch } = require('../../utils/context/search');
const { SPACE_BTWN_SEARCH_AND_SUGGEST } = require('../../utils/constants');
const { executeNative } = require('../../utils/mobile/nativeActions');
const { useSelector } = require('react-redux');
const { selectConfig } = require('../../redux/selectors/config');

const ENTER_KEYCODE = 13;

const { useEffect, useRef } = React;
/**
 * InputSearchV2 Component
 */
const normalizeArrowKey = (event) => {
  const { key, keyCode } = event;
  /* istanbul ignore next (ie) */
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0) {
    return `Arrow${key}`;
  }
  return key;
};

const InputSearchV2 = ({ searchedText }) => {
  const { value: searchValue, mask, selectedIndex, options, focus } = useSearchState();
  const dispatch = useSearchDispatch();
  const { baseUrl } = useUrlState();
  const { type: deviceType, nativeApp } = useDeviceState();
  const i18n = useI18n();
  const { isSearchButtonV2Enabled } = useSelector(selectConfig);

  const placeholder = isSearchButtonV2Enabled ? `${i18n.gettext('Buscá tu duda')}` : `${i18n.gettext('Escribe tu pregunta')}`;

  const inputRef = useRef();

  useEffect(() => {
    if (searchedText) {
      dispatch({ type: 'setSearchValue', data: searchedText });
    }
  }, [searchedText, dispatch]);

  const submit = (event) => {
    event.preventDefault();
    const highlightedOption = options[selectedIndex];

    if (highlightedOption) {
      window.location.assign(highlightedOption.href);
    } else {
      window.location.assign(`${baseUrl}/search?q=${searchValue}`);
    }
  };

  const handleChange = ({ target: { value } }) => {
    dispatch({ type: 'setSearchValue', data: value });
  };

  const handleFocus = (isFocused) => {
    const { width, height, top, left } = inputRef.current.getBoundingClientRect();
    const { scrollY } = window;
    const marginTop = SPACE_BTWN_SEARCH_AND_SUGGEST;
    const topPosition = top + height + scrollY + marginTop;
    dispatch({ type: 'setSearchPosition', data: { width, top: topPosition, left } });
    dispatch({ type: 'setSearchFocus', data: isFocused });
    if (isFocused && deviceType !== 'desktop') {
      window.scrollTo(0, top + scrollY);
    }
  };

  const handleClear = () => {
    dispatch({ type: 'setSearchValue', data: '' });
  };

  const handleKeyDown = (event) => {
    const {target} = event;
    if (target.name === 'clearSearch') return;

    const keyCode = event.which || event.keyCode;
    if (keyCode === ENTER_KEYCODE) {
      submit(event);
    }
    const key = normalizeArrowKey(event);

    if (key === 'ArrowDown') {
      dispatch({ type: 'setNextOption' });
      return;
    }

    if (key === 'ArrowUp') {
      dispatch({ type: 'setPrevOption' });
      return;
    }

    if (!focus && key !== 'Escape') {
      handleFocus(true);
      return;
    }

    if (key === 'Escape') {
      handleFocus(false);
    }
  };

  return (
    <div
      ref={inputRef}
      data-testid="input-search-v2"
      className="cx-search-box-v2"
    >
      {nativeApp
        ? (<div
            role="button"
            className="button-native"
            aria-label={placeholder}
            tabIndex="0"
            onClick={() => executeNative('cx_searchbar_open', {})}
        >
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
            <path fillOpacity=".45" d="M8.508 1c4.146 0 7.507 3.361 7.507 7.508 0 1.857-.674 3.557-1.791 4.867l4.577 4.578-.848.848-4.579-4.577c-1.31 1.117-3.01 1.791-4.866 1.791C4.36 16.015 1 12.654 1 8.508 1 4.36 4.361 1 8.508 1zm0 1.2C5.024 2.2 2.2 5.024 2.2 8.508c0 3.483 2.824 6.307 6.308 6.307 3.483 0 6.307-2.824 6.307-6.307 0-3.484-2.824-6.308-6.307-6.308z" />
          </svg>

          <span className="placeholder">{placeholder}</span>
        </div>)

        : (<SearchBox
            size="large"
            inputProps={{
              role: 'combobox',
              id: 'cb1-listbox',
              'aria-activedescendant': selectedIndex !== undefined ? `suggestion-search__link--selected-${selectedIndex}` : '',
              'aria-controls': 'cb2-listbox',
              'aria-expanded': options.length > 0,
            }}
            className="cx-input-search"
            placeholder={placeholder}
            value={mask || searchValue}
            onKeyDown={handleKeyDown}
            onTriggerClick={submit}
            onChange={handleChange}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            onClear={handleClear}
            label={placeholder}
            labelSrOnly
        />)}


    </div>
  );
};

InputSearchV2.propTypes = {
  i18n: shape({ gettext: func.isRequired }).isRequired,
  searchedText: string,
};

InputSearchV2.defaultProps = {
  searchedText: '',
};

module.exports = injectI18n(InputSearchV2);
