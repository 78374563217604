const SITEIDS = {
  mlb: 'MLB',
  mla: 'MLA',
  mlm: 'MLM',
  mco: 'MCO',
  mpe: 'MPE',
  mcr: 'MCR',
  mgt: 'MGT',
  mbo: 'MBO',
  mhn: 'MHN',
  mni: 'MNI',
  mlv: 'MLV',
  mpy: 'MPY',
  mpt: 'MPT',
  mcu: 'MCU',
  mrd: 'MRD',
  mlu: 'MLU',
  cbt: 'CBT',
};

module.exports = SITEIDS;
