/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');
const { UPDATE } = require('../actions/restriction');

const initialState = {
  userRestriction: null,
};
exports.initialState = initialState;

/**
 * Expose reducer
 */
exports.reducer = handleActions(
  {
    [UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
);
