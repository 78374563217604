const { resolveLink } = require('../../app/utils');

const TERMS_TO_FILTER = [
  'termos',
  'termos e condicoes',
  'terminos',
  'terminos y condiciones',
  'tyc',
];

exports.cleanString = (value) => {
  // Remove accents/diacritics
  let newValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Remove special characters
  newValue = newValue.replace(/[^\w\s]/gi, '');

  return newValue;
};
exports.filterContents = (contents) => {
  const contentsWithoutDuplicates = contents.filter(
    (content, index, self) => self.findIndex((c) => c.cleanText === content.cleanText) === index,
  );
  const hasAtLeastOneTerm = (text, terms) => terms.some((term) => text.toLowerCase().includes(term));

  return contentsWithoutDuplicates.filter(
    (content) => !hasAtLeastOneTerm(content.cleanText, TERMS_TO_FILTER),
  );
};

exports.parseContentResults = (results, baseUrl) => {
  const formattedContents = results.map((content, index) => ({
    ...content,
    cleanText: exports.cleanString(content.title),
    href: resolveLink(baseUrl, content, undefined, '#suggest'),
    key: `content-${index}`,
  }));

  return exports.filterContents(formattedContents);
};
