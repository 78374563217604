const React = require('react');

const { useSearchState, useSearchDispatch } = require('./context');
const { Search } = require('../../mobile/constants');
const { executeNative } = require('../../mobile/nativeActions');
const { useDeviceState } = require('../device');
const { isHelpView } = require('../../index');
const { CxSearchVisibility } = require('../../mobile/constants');
const { isSearchResultView, isAndroid } = require('../../index');
const { useUrlState } = require('../url');

const { useEffect } = React;

const topicMethods = {
  native_search_change: (dispatch, args) => dispatch({ type: 'setSearchValue', data: args.newText }),
  native_search_focus: (dispatch) => {
    dispatch({ type: 'setSearchFocus', data: true });
    document.body.style.overflow = 'hidden';
    window.scrollTo(0, 0);
  },

  native_search_blur: (dispatch, nativeApp, isSearchButtonV2Enabled, isAndroid, actualLocation, isYoshi) => {
    dispatch({ type: 'setSearchFocus', data: false });
    document.body.style.overflow = 'visible';

    if (nativeApp && isYoshi) {
      if (isSearchButtonV2Enabled) {
        executeNative(CxSearchVisibility, { visible: false });
      } else if (isAndroid && !isHelpView(actualLocation)) {
        executeNative(CxSearchVisibility, { visible: true });
      }
    }
  },
};

const useSuggestion = ({ baseUrl, listenMobileEvents, fullScreen, dataSource, limit, isSearchButtonV2Enabled, showNativeSearch, actualLocation }) => {
  const { focus, position, selectedIndex, options, value, isSearchValue } = useSearchState();
  const dispatch = useSearchDispatch();
  const { nativeApp } = useDeviceState();
  const selectOption = (index) => {
    dispatch({ type: 'setSelectedOptions', data: index });
  };
  const newSearchV2 = isSearchButtonV2Enabled || showNativeSearch;
  const { headers = {} } = useUrlState();
  const isYoshi = headers['x-webkit-custom'];

  useEffect(() => {
    if (dataSource) {
      dataSource(baseUrl)
        .then(response => dispatch({ type: 'setOptions', data: response.data, limit }))
        .catch(() => dispatch({ type: 'setOptions', data: [], limit }));
    }
  }, [dataSource, baseUrl, dispatch, limit]);

  useEffect(() => {
    if (window.MobileWebKit && listenMobileEvents && window.MobileWebKit.Engines
      && window.MobileWebKit.engine === window.MobileWebKit.Engines.V200) {
      window.MobileWebKit.events.subscribe(Search.SubscribeEvent.Focus, () => topicMethods[Search.SubscribeEvent.Focus](dispatch));

      window.MobileWebKit.events.subscribe(Search.SubscribeEvent.Change, (event) => {
        const {args} = event.detail;
        topicMethods[Search.SubscribeEvent.Change](dispatch, args);
      });

      window.MobileWebKit.events.subscribe(Search.SubscribeEvent.Blur, () => topicMethods[Search.SubscribeEvent.Blur](dispatch, nativeApp, isSearchButtonV2Enabled, isAndroid(nativeApp), actualLocation, isYoshi));
    } else if (window.MobileWebKit && listenMobileEvents) {
      const subscribeEvents = () => {
        window.MobileWebKit.executeNative({
          method: 'subscribe_event',
          args: { topic: 'native_search_change', event_method: 'native_search_change' },
        });

        window.MobileWebKit.executeNative({
          method: 'subscribe_event',
          args: { topic: 'native_search_focus', event_method: 'native_search_focus' },
        });

        window.MobileWebKit.executeNative({
          method: 'subscribe_event',
          args: { topic: 'native_search_blur', event_method: 'native_search_blur' },
        });

        window.MobileWebKit.on('native_request', (event) => {
          const topicMethod = topicMethods[event.method];
          if (topicMethod) topicMethod(dispatch, event.args);
        });
      };

      if (window.MobileWebKit.isConnected()) {
        subscribeEvents();
      } else {
        const connectionHandler = () => {
          window.MobileWebKit.removeEventListener('connect', connectionHandler);
          subscribeEvents();
        };

        window.MobileWebKit.addEventListener('connect', connectionHandler);
      }
    }
  }, [dispatch, listenMobileEvents]);

  let isOpen = Boolean(focus && options.length);
  if (isSearchButtonV2Enabled && nativeApp) {
    isOpen = Boolean(focus);
  }

  if (newSearchV2 && isAndroid(nativeApp) && isSearchResultView(actualLocation)) {
    isOpen = Boolean(options.length && isSearchValue);
  }

  return {
    isOpen,
    containerStyle: fullScreen ? { height: '100%' } : { ...position, height: 'auto' },
    selectedIndex,
    options,
    value,
    selectOption,
  };
};

module.exports = {
  useSuggestion,
};
