const React = require('react');
const { I18nProvider } = require('nordic/i18n');
const { objectOf, any, element } = require('prop-types');
const { I18nContext } = require('./i18nContext');
const { UserProvider } = require('./user');
const { DeviceProvider } = require('./device');
const { PlatformProvider } = require('./platform');
const { UrlProvider } = require('./url');
const { FlagsProvider } = require('./flags');
const { AndesProvider } = require('@andes/context');
const { ChatProvider } = require('./chatProvider');

const GlobalProviders = ({ children, value: { i18n, platform, device, user, urlState, flags, locale } }) => {
  const { domain } = platform;
  if (domain && domain.includes('mercadoshops')) {
    platform.id = 'MS';
  }

  return (
    <I18nProvider i18n={i18n}>
      <I18nContext.Provider value={i18n}>
        <PlatformProvider value={platform}>
          <DeviceProvider value={device}>
            <UserProvider value={user}>
              <UrlProvider value={{...urlState, headers: {...urlState.headers, cookie: undefined, authorization: undefined }}}>
                <FlagsProvider value={flags}>
                  <AndesProvider locale={locale}>
                    <ChatProvider>
                      {children}
                    </ChatProvider>
                  </AndesProvider>
                </FlagsProvider>
              </UrlProvider>
            </UserProvider>
          </DeviceProvider>
        </PlatformProvider>
      </I18nContext.Provider>
    </I18nProvider>
  );
};

GlobalProviders.propTypes = {
  children: element.isRequired,
  value: objectOf(any).isRequired,
};

module.exports = {
  GlobalProviders,
};
