/**
 * Module dependencies
 */
const React = require('react');
const { arrayOf, any, bool } = require('prop-types');
const classNames = require('classnames');

const PurchaseAmount = require('../Purchase/Amount');

const BillSection = ({ items, separator, isTotal, textOverflow }) => {
  if (!items) {
    return null;
  }

  return (
    <div className="payment-bill-section">
      {separator && <hr className="payment-bill-section__separator" />}

      {items.map(({ label, amount, installments = {}, interest = {} }) => (
        <div
          key={`bill-section-item-${label}`}
          className={classNames('payment-bill-section__item', {
            'payment-bill-section__item--total': isTotal,
          })}
        >
          <div
            className={classNames('payment-bill-section__item-label', {
              'payment-bill-section__item-label--total': isTotal,
              'payment-bill-section__item-label--overflow': textOverflow,
            })}
          >
            {label}
          </div>
          <div className="payment-bill-section__item-value">
            <PurchaseAmount data={amount} installments={installments.text} interest={interest.text} isTotal={isTotal} />
          </div>
        </div>
      ))}
    </div>
  );
};

BillSection.propTypes = {
  items: arrayOf(any),
  separator: bool,
  isTotal: bool,
  textOverflow: bool,
};
BillSection.defaultProps = {
  items: [],
  separator: false,
  isTotal: false,
  textOverflow: false,
};

module.exports = BillSection;
