/* eslint-disable global-require */
/**
 * Module dependencies
 */
const getOngoingChat = () => {
  try {
    const domPageToken = document.querySelector('meta[name="page-token"]');
    const pageToken = domPageToken ? domPageToken.getAttribute('content') : '';
    const endpoint = '/ongoing-chat-v3';

    const browserRestClient = require('../utils/restclient-browser')();
    return browserRestClient.get(endpoint, {
      headers: { 'CSRF-Token': pageToken },
    });
  } catch (e) {
    return {};
  }
};

module.exports = {
  getOngoingChat,
};
