/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const Transfer = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="transfer-a" cx="24" cy="24" r="24" />
      <rect id="transfer-c" width="16" height="16" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="transfer-b" fill="#fff">
          <use xlinkHref="#transfer-a" />
        </mask>
        <use fill="#FFF" xlinkHref="#transfer-a" />
        <g fill="#3483FA" fillRule="nonzero" mask="url(#transfer-b)">
          <path d="M30.081 28.854v-7.517l-5.257.006v7.511h5.257zm1.5 0h1.24l2.24 5.265H13.24l2.071-5.265h1.268v-7.502l-2.254.003v-3.469l9.75-5.67 9.75 5.67v3.447l-2.244.003v7.518zm-1.5 1.5H16.333l-.891 2.265h17.35l-.963-2.265h-1.748zm-6.757-1.5v-7.51l-5.245.007v7.503h5.245zM15.825 18.75v1.104l16.5-.018v-1.086l-8.25-4.797-8.25 4.797z" />
        </g>
      </g>
      <g transform="translate(34 34)">
        <mask id="transfer-d" fill="#fff">
          <use xlinkHref="#transfer-c" />
        </mask>
        {status === 'success' && (
          <g mask="url(#transfer-d)">
            <path fill="#00A650" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.788 9.396l4.577-4.577 1.028 1.029-5.605 5.605-3.181-3.181 1.028-1.029z"
            />
          </g>
        )}
        {status === 'warning' && (
          <g mask="url(#transfer-d)">
            <path fill="#F73" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
        {status === 'error' && (
          <g mask="url(#transfer-d)">
            <path fill="#F23D4F" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
      </g>
    </g>
  </svg>
);

Transfer.propTypes = {
  status: string,
};
Transfer.defaultProps = {
  status: '',
};

module.exports = Transfer;
