/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const MedioOff = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="mediooff-a" cx="24" cy="24" r="24" />
      <rect id="mediooff-c" width="16" height="16" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="mediooff-b" fill="#fff">
          <use xlinkHref="#mediooff-a" />
        </mask>
        <use fill="#FFF" xlinkHref="#mediooff-a" />
        <g fill="#3483FA" fillRule="nonzero" mask="url(#mediooff-b)">
          <path d="M18.748 17.25h1.5v13.5h-1.5v-13.5zm-5.99 0h1.5v13.5h-1.5v-13.5zm2.21 0h1.5v13.5h-1.5v-13.5zm7.502 0h1.5v13.5h-1.5v-13.5zm2.277 0h1.5v13.5h-1.5v-13.5zm4.497 0h1.5v13.5h-1.5v-13.5zm2.272 0h1.5v13.5h-1.5v-13.5zm2.226 0h1.5v13.5h-1.5v-13.5z" />
        </g>
      </g>
      <g transform="translate(34 34)">
        <mask id="mediooff-d" fill="#fff">
          <use xlinkHref="#mediooff-c" />
        </mask>
        {status === 'success' && (
          <g mask="url(#mediooff-d)">
            <path fill="#00A650" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.788 9.396l4.577-4.577 1.028 1.029-5.605 5.605-3.181-3.181 1.028-1.029z"
            />
          </g>
        )}
        {status === 'warning' && (
          <g mask="url(#mediooff-d)">
            <path fill="#F73" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
        {status === 'error' && (
          <g mask="url(#mediooff-d)">
            <path fill="#F23D4F" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
      </g>
    </g>
  </svg>
);

MedioOff.propTypes = {
  status: string,
};
MedioOff.defaultProps = {
  status: '',
};

module.exports = MedioOff;
