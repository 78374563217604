/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const AgreedPayment = require('./Icons/AgreedPayment');
const DebitCard = require('./Icons/DebitCard');
const Account = require('./Icons/Account');
const Cash = require('./Icons/Cash');
const CreditCard = require('./Icons/CreditCard');
const MedioOff = require('./Icons/MedioOff');
const Pago = require('./Icons/Pago');
const Ticket = require('./Icons/Ticket');
const Transfer = require('./Icons/Transfer');
const Location = require('./Icons/Location');
const PayPal = require('./Icons/PayPal');

const Icons = ({ name, status }) => {
  const components = {
    account: Account,
    agreed_payment: AgreedPayment,
    cash: Cash,
    credit_card: CreditCard,
    debit_card: DebitCard,
    mediooff: MedioOff,
    pago: Pago,
    ticket: Ticket,
    transfer: Transfer,
    location: Location,
    paypal: PayPal,
  };
  const IconComponent = components[name];

  return IconComponent ? <IconComponent status={status} /> : null;
};

Icons.propTypes = {
  name: string.isRequired,
  status: string,
};
Icons.defaultProps = {
  status: '',
};

module.exports = Icons;
