/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any } = require('prop-types');
const { connect } = require('react-redux');
const classNames = require('classnames');

const Title = require('../components/Title');
const Bill = require('./Bill');

const Charges = ({ header, bill }) => {
  const baseClass = 'widget-charges';

  return (
    <div className={classNames([baseClass, 'widget-purchase'])}>
      <Title {...header} />

      <Bill {...bill} hasTitle />
    </div>
  );
};

Charges.propTypes = {
  header: objectOf(any),
  bill: objectOf(any),
};
Charges.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { charges } }) => ({
  ...charges,
});

const ConectedCharges = connect(
  mapStateToProps,
  {},
)(Charges);

module.exports = ConectedCharges;
