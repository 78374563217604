module.exports = {
  ORIGINS: {
    MP: 'MP_PORTAL,MP_PORTAL_MOBILE,PORTAL_MP,PORTAL_MP_MOBILE,SUPPORT_WIDGET_MP_MOBILE,SUPPORT_WIDGET_MP,MPCX_ML_WIDGET_ACCREDITATION,MPCX_MP_WIDGET_REFUNDS,MPCX_ML_WIDGET_REFUNDS,MPCX_MP_WIDGET_ACCREDITATION,MP Point,MP_SUPPORT_FORM',
    ML: 'ML_PORTAL,ML_PORTAL_MOBILE,PORTAL_ML,PORTAL_ML_MOBILE,MYML,SUPPORT_WIDGET_ML,SUPPORT_WIDGET_ML_MOBILE,SUPPORT_WIDGET_MOBILE,MYML_MOBILE,MY_PROFILE',
  },
  MLBUS: ['ML', 'MS'],
  WEBVIEW_BASE: {
    mercadolibre: 'meli',
    mercadopago: 'mercadopago',
    mercadoenviosextra: 'meli',
    smartpos: 'mercadopago',
  },
  WEBVIEW_CUSTOM_PORTAL_VALID: [
    {
      prefix: '/envios-extra',
      version: '2.22.0',
    },
  ],
  MLHelpBaseDeeplink: 'meli://cx/help?url=',
  MPHelpBaseDeeplink: 'mercadopago://cx/help?url=',
  APPS: ['items', 'questions', 'buyer_questions', 'commissions', 'sells', 'purchases', 'feedbacks', 'buyer_feedbacks', 'report_user', 'orders', 'packs', 'payments', 'contracts', 'warranties', 'reservation', 'charges', 'withdrawals'],
  RESTRICTED_USER_FORMS: [113, 7141, 284, 112, 131, 225, 309, 61, 284, 7288, 125, 122, 7865, 7976, 8279, 8281, 8239, 8204, 8203],
  TOPS_ONLY_HUBS: [16180, 7239, 7292],
  DEEPLINK_INTERNAL_PROTOCOLS: ['meli://', 'mercadopago://'],
  DEEPLINK_EXTERNAL_PROTOCOLS: ['tel:'],
  SPACE_BTWN_SEARCH_AND_SUGGEST: 12,
  SEARCH_VIEW_PATH: '/search?q=',
  HELP_VIEW_PATH_ES: '/ayuda/',
  HELP_VIEW_PATH_PT: '/ajuda/',
  BUTTON_STYLE_BACK: 'back',
  BUTTON_STYLE_NONE: 'none',
  BUTTON_WHITE_COLOR: 'FFFFFF',
  MERCHANTS_CREDITS_FORM_ID: 8616,
  MP_NEW_VARIANTS: {
    default: 'default',
    wallet: 'wallet',
    financiera: 'financiera',
  },
  MERCHANTS_CREDITS_SIMULATOR_URL: '/merchant/enrollment/fixed-term-loan/simulation?onboarding=false',
  ITO_FORM_ID: 8832,
  VERDI_LINK_TYPES: {
    VERDI_LINK: 'VerdiLink',
    DEFAULT: 'DEFAULT',
  },
  BUSINESS_UNITS: {
    ML: 'ML',
    MP: 'MP',
  },
  OPTION_TYPES: {
    HISTORY: 'history',
  },
  METRICS: [
    { name: 'contact_hub_traffic', type: 'increment', allowValues: [1] },
    { name: 'contact.redirect.native_click', type: 'increment', allowValues: [1] },
    { name: 'contact.redirect.desktop_click', type: 'increment', allowValues: [1] },
    { name: 'contact.redirect.native_mobile', type: 'increment', allowValues: [1] },
    { name: 'contact.redirect.one_p_contact', type: 'increment', allowValues: [1] },
    { name: 'contact.redirect.login', type: 'increment', allowValues: [1] },
    { name: 'experiment_contact_button.redirect.verdi', type: 'increment', allowValues: [1] },
    { name: 'experiment_contact_button.redirect.original_flow', type: 'increment', allowValues: [1] },
  ],
};
