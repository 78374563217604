/**
 * Module dependencies
 */
const RelatedContents = require('./RelatedContents');
const Search = require('./Search');
const Payment = require('./Payment');
const Purchase = require('./Purchase');
const Product = require('./Product');
const Pack = require('./Pack');
const Item = require('./Item');
const Charges = require('./Charges');
const Commissions = require('./Commissions');
const Sell = require('./Sell');
const Empty = require('./Empty');

module.exports = {
  relatedContents: RelatedContents,
  search: Search,
  payment: Payment,
  product: Product,
  purchase: Purchase,
  pack: Pack,
  item: Item,
  charges: Charges,
  commissions: Commissions,
  sell: Sell,
  sellerPack: Sell,
  empty: Empty,
};
