/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const AgreedPayment = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="agreed-payment-a" cx="24" cy="24" r="24" />
      <rect id="agreed-payment-b" width="16" height="16" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#agreed-payment-a" />
      <path
        fill="#3483FA"
        d="M33.06 29.67L29.33 33.4a4.447 4.447 0 0 1-5.447.66l-.007-.004a3.752 3.752 0 0 1-4.278-.729l-3.257-3.257a3.782 3.782 0 0 1-.349-.402H12.5v3H8v-1.5h3v-13.5H8.001v-1.5H12.5v1.5h5.436L26.891 13l4.67 4.67H35v-1.5h4.501v1.5H36.5v13.5h3v1.5H35v-3h-1.94zm.041-1.5H35v-9h-4.06l-4.331-4.331-7.84 4.088.04.075a2.25 2.25 0 0 0 2.53 1.122l4.186-1.046 7.576 9.091zm-20.601 0h2.818a3.747 3.747 0 0 1 1.023-3.402l.257-.258a3.75 3.75 0 0 1 5.304 0l3.257 3.258a3.75 3.75 0 0 1 .038 5.265 2.947 2.947 0 0 0 3.072-.693l3.466-3.467-6.76-8.112-3.273.818a3.75 3.75 0 0 1-4.216-1.87l-.289-.54H12.5v9zm4.902.84l3.257 3.258a2.25 2.25 0 0 0 3.182 0l.257-.258a2.25 2.25 0 0 0 0-3.182l-3.257-3.257a2.25 2.25 0 0 0-3.182 0l-.257.257a2.25 2.25 0 0 0 0 3.182z"
      />
      <g>
        <g transform="translate(34 34)">
          <mask id="agreed-payment-c" fill="#fff">
            <use xlinkHref="#agreed-payment-b" />
          </mask>
          {status === 'success' && (
            <g mask="url(#agreed-payment-c)">
              <path fill="#00A650" d="M-.914-.457h18v17h-18z" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M6.788 9.396l4.577-4.577 1.028 1.029-5.605 5.605-3.181-3.181 1.028-1.029z"
              />
            </g>
          )}
          {status === 'warning' && (
            <g mask="url(#agreed-payment-c)">
              <path fill="#F73" d="M-.914-.457h18v17h-18z" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
              />
            </g>
          )}
          {status === 'error' && (
            <g mask="url(#agreed-payment-c)">
              <path fill="#F23D4F" d="M-.914-.457h18v17h-18z" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
              />
            </g>
          )}
        </g>
      </g>
    </g>
  </svg>
);

AgreedPayment.propTypes = {
  status: string,
};
AgreedPayment.defaultProps = {
  status: '',
};

module.exports = AgreedPayment;
