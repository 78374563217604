const { useCallback, useEffect, useRef } = require('react');
const { useCallActionOnFocused } = require('./useCallActionOnFocused');

const useInvokeActionOnUserInteraction = ({
  action,
  cooldown = 20000,
  isInitialInvokeDone = false,
  isMobile,
  invokeActionOnFocused = true,
  invokeActionOnUserActivity = true,
}) => {
  const initialTime = isInitialInvokeDone ? Date.now() : 0;
  const lastExecutionTimeRef = useRef(initialTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAction = useCallback(action, []);

  useEffect(() => {
    if (!invokeActionOnUserActivity) return;

    const handleInteraction = () => {
      const currentTime = Date.now();
      if (currentTime - lastExecutionTimeRef.current >= cooldown) {
        memoizedAction();
        lastExecutionTimeRef.current = currentTime;
      }
    };

    if (isMobile) {
      window.addEventListener('touchstart', handleInteraction);
    } else {
      window.addEventListener('mousemove', handleInteraction);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('touchstart', handleInteraction);
      } else {
        window.removeEventListener('mousemove', handleInteraction);
      }
    };
  }, [cooldown, invokeActionOnUserActivity, isMobile, memoizedAction]);

  if (invokeActionOnFocused) {
    useCallActionOnFocused({
      action: memoizedAction,
      isInitialInvokeDone,
      isMobile,
      callbackAfterInvokeAction: () => {
        lastExecutionTimeRef.current = Date.now();
      },
    });
  }

  return null;
};

module.exports = {
  useInvokeActionOnUserInteraction,
};
