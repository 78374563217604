/**
 * Module dependencies
 */
const React = require('react');
const get = require('lodash/get');
const { objectOf, any, string } = require('prop-types');

const StatusCard = require('../components/StatusCard');

const ErrorReason = ({ text = '' }) => <p>{text}</p>;

const Description = ({ firstPayment, secondPayment }) => {
  let response = [
    <p key="method-description__firstPayment.description.text">{get(firstPayment, 'description.text', '')}</p>,
  ];

  if (secondPayment) {
    response = [
      ...response,
      <p key="method-description__firstPayment.title.text">{get(firstPayment, 'title.text', '')}</p>,
      <p key="method-description__secondPayment.description.text">{get(secondPayment, 'description.text', '')}</p>,
      <p key="method-description__secondPayment.title.text">{get(secondPayment, 'title.text', '')}</p>,
    ];
  }

  return response;
};

const Method = ({
  icon,
  status_icon: statusIcon,
  title,
  subtitle,
  failure,
  error_reason: errorReason,
  first_payment: firstPayment,
  second_payment: secondPayment,
}) => (
  <StatusCard
    icon={icon}
    statusIcon={statusIcon}
    labelStatus={title}
    title={firstPayment.title ? firstPayment.title.text : ''}
  >
    {subtitle && <p>{subtitle}</p>}
    {failure && failure.text && <p>{failure.text}</p>}
    {errorReason ? (
      <ErrorReason text={errorReason.text} />
    ) : (
      <Description firstPayment={firstPayment} secondPayment={secondPayment} />
    )}
  </StatusCard>
);

Method.propTypes = {
  icon: string,
  status_icon: string,
  title: string,
  subtitle: string,
  failure: objectOf(any),
  error_reason: objectOf(any),
  first_payment: objectOf(any),
  second_payment: objectOf(any),
};
Method.defaultProps = {};

module.exports = Method;
