/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');
const { UPDATE } = require('../actions/base');

const initialState = {
  platform: undefined,
  now: undefined,
  userImage: '',
  device: undefined,
  userName: '',
  isNativeMobile: false,
};
exports.initialState = initialState;

/**
 * Expose reducer
 */
exports.reducer = handleActions(
  {
    [UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
);
