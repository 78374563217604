const React = require('react');

const FlagsContext = React.createContext();

const FlagsProvider = ({ children, value }) => (
  <FlagsContext.Provider value={value}>
    {children}
  </FlagsContext.Provider>
);

const useFlags = () => {
  const context = React.useContext(FlagsContext);
  if (!context) {
    throw new Error('UseFlags must be used within a provider');
  }

  return context;
};

module.exports = {
  FlagsProvider,
  useFlags,
};
