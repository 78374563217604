const React = require('react');
const { chatDataInitialState } = require('./chatActive/constants');

const ChatContext = React.createContext(undefined);
const ChatDispatch = React.createContext(undefined);

const ChatReducer = (state, action) => {
  switch (action.type) {
    case 'setChatData':
      return {
        ...state,
        ...action.chatDataWithFormRef,
      };
    case 'setChatReference':
      return {
        ...state,
        chatReference: action.chatReference,
      };
    default:
      throw new Error('Failed mapping ChatReducer action');
  }
};

const ChatProvider = ({ children, initChatDataValue = chatDataInitialState }) => {
  const [state, dispatch] = React.useReducer(ChatReducer, initChatDataValue);
  return (
    <ChatContext.Provider value={state}>
      <ChatDispatch.Provider value={dispatch}>
        {children}
      </ChatDispatch.Provider>
    </ChatContext.Provider>
  );
};

const useChatState = () => {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('UseChatState must be used within a provider');
  }

  return context;
};

const useChatDispatch = () => {
  const dispatch = React.useContext(ChatDispatch);
  if (dispatch === undefined) {
    throw new Error('useChatDispatch must be used within a provider');
  }

  return dispatch;
};

module.exports = {
  ChatProvider,
  useChatState,
  useChatDispatch,
  ChatContext,
};
