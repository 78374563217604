/**
 * Module dependencies
 */
const React = require('react');
const { shape, arrayOf, object, func, string, bool } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const SearchInput = require('../../../InputSearch');
const ContentList = require('../../../ContentList');
const { usePlatformState } = require('../../../../utils/context/platform');

const RelatedContents = ({ contents, showSearch, title, i18n }) => {
  const { siteId } = usePlatformState();
  const widgetTitle = title || i18n.gettext('Ayudas relacionadas');
  i18n.gettext('Estos temas pueden interesarte');
  return (
    <div className="cx-peach__widget--related-contents">
      {showSearch && <SearchInput siteId={siteId} />}
      {contents.length > 0
      && (
      <div className="related-contents__container">
        <h2>{widgetTitle}</h2>
        <ContentList data={{ contents }} showChevron />
      </div>
      )}
    </div>
  );
};

RelatedContents.propTypes = {
  contents: arrayOf(object),
  showSearch: bool,
  title: string,
  i18n: shape({ gettext: func.isRequired }).isRequired,
};

RelatedContents.defaultProps = {
  contents: [],
  showSearch: true,
  title: '',
};

module.exports = injectI18n(RelatedContents);
