const React = require('react');
const { ContactButtonModule } = require('cx-contact-button-lib');
const { SOURCE_ID_MP, SOURCE_ID_ML, FLOW_AA_ML, FLOW_AA_MP, SCOPE_PRODUCTION } = require('./constants');

const FloatingButtonModuleV4 = ({
  bu,
  channel = null,
  countryId,
  domain,
  isCbt = false,
  isMobile = false,
  scope,
  context,
  hasCreditRestriction,
  loadingComponent,
  verdiChatLink,
}) => (
  <ContactButtonModule
    bu={bu}
    channel={channel}
    countryId={countryId}
    domain={domain}
    isCbt={isCbt}
    isMobile={isMobile}
    scope={scope === SCOPE_PRODUCTION ? undefined : scope}
    flow={bu === 'MP' ? FLOW_AA_MP : FLOW_AA_ML}
    sourceId={bu === 'MP' ? SOURCE_ID_MP : SOURCE_ID_ML}
    context={context}
    hasCreditRestriction={hasCreditRestriction}
    loadingComponent={loadingComponent}
    verdiChatLink={verdiChatLink}
  />
);
module.exports = FloatingButtonModuleV4;
