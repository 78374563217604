/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any, shape, func } = require('prop-types');
const { connect } = require('react-redux');
const injectI18n = require('nordic/i18n/injectI18n');

const Title = require('../components/Title');
const SingleCard = require('./SingleCard');
const PurchaseShippingDetails = require('../Purchase/ShippingDetails');

const Product = ({ i18n, purchaseSummary, shipping, product }) => {
  const baseClass = 'widget-product';

  const ship = shipping || {};

  return (
    <div className={baseClass}>
      <Title
        title={i18n.gettext('Detalle del producto')}
        subtitle={purchaseSummary.dateCreated}
        orderNumber={purchaseSummary.orderNumber}
      />

      <SingleCard status={ship.status || ''} label={ship.text || ''} product={product} />

      {shipping && <PurchaseShippingDetails {...shipping} />}
    </div>
  );
};

Product.propTypes = {
  i18n: shape({
    gettext: func.isRequired,
  }).isRequired,
  purchaseSummary: objectOf(any),
  shipping: objectOf(any),
  product: objectOf(any),
};
Product.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { product } }) => ({
  ...product,
});

module.exports = injectI18n(
  connect(
    mapStateToProps,
    {},
  )(Product),
);
