/**
 * Module dependencies
 */
const React = require('react');

const Empty = () => (
  <div className="cx-peach__widget--empty" data-testid="empty-widget" />
);

Empty.propTypes = {
};

Empty.defaultProps = {
};

module.exports = Empty;
