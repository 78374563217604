/**
 * Module dependencies
 */
const React = require('react');
const { object, string, oneOfType } = require('prop-types');

const SvgToComponent = ({ svg, className }) => {
  if (typeof svg === 'string') {
    return <div className={`svg-to-component ${className}`} dangerouslySetInnerHTML={{ __html: svg }} />;
  }
  if (React.isValidElement(svg)) {
    return (
      <div className={`svg-to-component ${className}`}>
        { svg }
      </div>
    );
  }
  return null;
};

/**
 * Define props
 */
SvgToComponent.propTypes = {
  svg: oneOfType([object, string]).isRequired,
  className: string,
};

SvgToComponent.defaultProps = {
  className: '',
};

module.exports = SvgToComponent;
