/* eslint-disable global-require */
const EnvPageService = require('../browserServices/envPageService');

const BrowserRestClient = (overrideApiBasePath, overrideTimeout) => {
  const timeout = 1000 * 60;

  return require('nordic/restclient')({
    baseURL: overrideApiBasePath || EnvPageService.apiBasePath,
    timeout: overrideTimeout || timeout,
  });
};

module.exports = BrowserRestClient;
