const React = require('react');
const fuseModule = require('fuse.js/dist/fuse.common');

const getMaxPair = (pairs) => {
  const { pair } = pairs.reduce((maxPair, currentPair) => {
    const weight = currentPair[1] - currentPair[0];
    return weight > maxPair.weight ? { weight, pair: currentPair } : maxPair;
  }, { weight: 0 });

  return pair;
};

const highlight = (value, indices = []) => {
  const pair = getMaxPair(indices);

  if (!pair) return value;

  return (
    <>
      {value.substring(0, pair[0])}
      <strong>{value.substring(pair[0], pair[1] + 1)}</strong>
      {value.substring(pair[1] + 1)}
    </>
  );
};

const getOptions = (state) => {
  const { history = [], contents = [], limit } = state;
  const value = state.value?.trim();

  const Fuse = fuseModule.default || fuseModule;
  let matchedContents = value ? contents : [];
  let matchedHistory = history.map(item => ({ item }));

  if (Fuse && value) {
    const fuseContents = new Fuse(contents, {
      keys: ['cleanText'],
      includeMatches: true,
      threshold: 0.4,
    });
    matchedContents = fuseContents.search(value);
    matchedHistory = [];
  }

  const limitedOptions = [...matchedHistory, ...matchedContents].slice(0, limit);
  const highlightedOptions = limitedOptions.map(({ matches = [], item }) => {
    const match = matches.find(m => m.key === 'cleanText') || {};
    return { ...item, highlightedText: highlight(item.title, match.indices) };
  });

  return highlightedOptions;
};

module.exports = {
  getOptions,
};
