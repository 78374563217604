/**
 * Module dependencies
 */
const React = require('react');
const classnames = require('classnames');
const { arrayOf, any, shape, bool, string, oneOf, func } = require('prop-types');

const { Card, CardHeader } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const SvgToComponent = require('./SvgToComponent');
const { useDeviceState } = require('../../utils/context/device');
const { useI18n } = require('../../utils/context/i18nContext');

const { useState } = React;


const ContentList = ({
  data,
  header,
  showChevron,
  alignIcons,
  useIcons,
  buildMelidataTrack,
  hasSubtitle,
  colorOnHover,
  itemClass,
  title,
}) => {
  const i18n = useI18n();
  const { type: deviceType, webView: isNativeMobile } = useDeviceState();
  const [hoveredItem, setHoveredItem] = useState({});

  const onClickEvent = (e, value, content) => {
    let melidataTrack = null;

    if (buildMelidataTrack) {
      melidataTrack = buildMelidataTrack(content);
    }

    if (content.onClick) content.onClick();
    if (typeof window !== 'undefined' && window.melidata && melidataTrack) {
      window.melidata('cleanAndSend', 'event', melidataTrack);
    }
  };

  const handleItemHover = (status, index) => {
    if (colorOnHover && !isNativeMobile) {
      const auxObj = {
        [index]: status,
      };
      setHoveredItem({ ...auxObj });
    }
  };
  const portalContentTranslated = i18n.gettext('Información de la sección {0}', `${title}`);

  return (
    <div className="cx-contents-list" key={`${data.title}${data.component}`}>
      <Card paddingSize={0} className={classnames('cx-contents-list__card', { 'custom-portals': hasSubtitle })}>
        {
          header
          && (
            <CardHeader>
              <h2>{header}</h2>
            </CardHeader>
          )
        }
        <List srLabel={portalContentTranslated} withDividers>
          {data.contents.map((content, index) => {
            const iconClass = `cx-contents-list__icon cx-contents-list__icon--${alignIcons}`;
            const contentIcon = deviceType === 'desktop' ? content.icon : (content.mobile_icon || content.icon);
            const image = useIcons && contentIcon
              ? <SvgToComponent svg={contentIcon} className={iconClass} />
              : null;
            return (
              <ListItem
                key={content.id}
                className={classnames(
                  'cx-list-item',
                  itemClass,
                  { desktop: deviceType === 'desktop' },
                  { alignIconsTop: alignIcons === 'top' },
                  { 'andes-list__item-with-image': !!image },
                )}
                title={content.title}
                href={content.href}
                description={content.description}
                leftContent={image}
                onClick={(e, value) => onClickEvent(e, value, content)}
                chevron={showChevron}
                rightContentPosition="centered"
                onMouseEnter={() => handleItemHover(true, index)}
                onMouseLeave={() => handleItemHover(false, index)}
                selected={hoveredItem[index]}
                target={content.target ?? '_self'}
              />
            );
          })}
        </List>
      </Card>
    </div>
  );
};
/**
 * Define props
 */
ContentList.propTypes = {
  data: shape({
    contents: arrayOf(shape(any)).isRequired,
  }).isRequired,
  header: string,
  showChevron: bool,
  alignIcons: oneOf(['top', 'center']),
  useIcons: bool,
  buildMelidataTrack: func,
  hasSubtitle: bool,
  colorOnHover: bool,
  itemClass: string,
};

ContentList.defaultProps = {
  header: null,
  showChevron: false,
  listSize: 'default',
  alignIcons: 'center',
  useIcons: false,
  buildMelidataTrack: null,
  hasSubtitle: false,
  colorOnHover: false,
  itemClass: '',
};

module.exports = ContentList;
