/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any } = require('prop-types');
const { connect } = require('react-redux');
const classNames = require('classnames');
const injectI18n = require('nordic/i18n/injectI18n');

const Title = require('../components/Title');
const ProductSummary = require('../Product/Summary');

const Item = ({
  i18n,
  statusText,
  status,
  idText,
  listingTypeText,
  secure_thumbnail: secureThumbnail,
  title,
  available_quantity: availableQuantity,
  currencyData,
}) => {
  const baseClass = 'widget-item';

  return (
    <div className={classNames([baseClass, 'widget-purchase'])}>
      <Title
        title={i18n.gettext('Detalle de la publicación')}
        subtitle={[`${statusText || status} | ${idText}`, listingTypeText]}
      />

      <ul className="widget-item__list">
        <ProductSummary
          data={{
            image: secureThumbnail,
            title,
            amount: {
              symbol: currencyData.symbol,
              fraction: currencyData.fraction,
              cents: currencyData.cents,
              currency_id: currencyData.id,
            },
            quantity: i18n.ngettext('x {0} unidad', 'x {0} unidades', availableQuantity, [availableQuantity]),
          }}
        />
      </ul>
    </div>
  );
};

Item.propTypes = {
  header: objectOf(any),
  shipping: objectOf(any),
  pack: objectOf(any),
};
Item.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { item } }) => ({
  ...item,
});

const ConectedItem = injectI18n(
  connect(
    mapStateToProps,
    {},
  )(Item),
);

module.exports = ConectedItem;
