const { useEffect } = require('react');
const ContactInProgressIllustration = require('./ContactInProgressIllustration');
const React = require('react');
const { EXPANDED_LIMIT, ANDES_CLASS_BUTTON, BASE_CLASS_NAME, EXPANDED_CLASS_NAME, MOBILE_CLASS_NAME } = require('./constants');
const { useI18n } = require('../../utils/context/i18nContext');
const CHANNEL_TYPES = require('./channels');

const ContactInProgressButton = ({
  channel,
  handleClick,
  bu,
  isMobile,
}) => {
  const i18n = useI18n();
  const [offset, setOffset] = React.useState(0);
  const mobileClassName = isMobile ? `${BASE_CLASS_NAME}${MOBILE_CLASS_NAME}` : '';
  const expandedClassName = offset <= EXPANDED_LIMIT ? `${BASE_CLASS_NAME}${EXPANDED_CLASS_NAME}` : '';
  const floatingButtonBU = `${BASE_CLASS_NAME}_${bu}`;
  const className = `${floatingButtonBU} ${BASE_CLASS_NAME} ${mobileClassName} ${channel === CHANNEL_TYPES.none ? expandedClassName : ''}`;

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <button
      data-testid="floating-button"
      onClick={handleClick}
      className={`${className} ${ANDES_CLASS_BUTTON}`}
    >
      <ContactInProgressIllustration channel={channel} color="white" />{' '}
      {channel === CHANNEL_TYPES.none && <p>{i18n.gettext('Contáctanos')}</p>}
    </button>
  );
};

module.exports = ContactInProgressButton;
