const React = require('react');

const UserContext = React.createContext();

const UserProvider = ({ children, value }) => (
  <UserContext.Provider value={value || {}}>
    {children}
  </UserContext.Provider>
);

const useUserState = () => React.useContext(UserContext);

module.exports = {
  UserProvider,
  useUserState,
};
