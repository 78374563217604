const React = require('react');
const { string, arrayOf, shape } = require('prop-types');
const { Breadcrumb: AndesBreadcrumb, BreadcrumbLevel } = require('@andes/breadcrumb');

const { useI18n } = require('../../utils/context/i18nContext');
const { useUrlState } = require('../../utils/context/url');
const { removeDuplicatedBreadcrumbs, replaceBreadcrumbWithDots } = require('./utils');

const Breadcrumb = ({ breadcrumbLevels }) => {
  const i18n = useI18n();
  const { baseUrl } = useUrlState();

  const rootLevel = {
    id: '0',
    text: i18n.gettext('Ayuda'),
    href: baseUrl,
  };

  const localBreadcrumbLevels = removeDuplicatedBreadcrumbs(breadcrumbLevels, i18n.gettext('Resultados de búsqueda'));

  const processedBreadcrumbs = localBreadcrumbLevels.map((b, i) => {
    const breadcrumb = { id: b.id, text: b.text, disabled: true };
    if (i !== localBreadcrumbLevels.length - 1) {
      breadcrumb.href = b.href;
      breadcrumb.disabled = false;
    }

    return breadcrumb;
  });

  const enhancedLevels = [rootLevel, ...replaceBreadcrumbWithDots(processedBreadcrumbs)];
  const ariaTranslated = i18n.gettext('lista de páginas');

  return (
    <div className="cx-breadcrumb__wrapper">
      <AndesBreadcrumb className="breadcrumb" srLabel={ariaTranslated}>
        {enhancedLevels.map((level) => (
          <BreadcrumbLevel {...level} />
        ))}
      </AndesBreadcrumb>
    </div>
  );
};

Breadcrumb.propTypes = {
  breadcrumbLevels: arrayOf(shape({
    id: string,
    text: string,
    href: string,
  })),
};

Breadcrumb.defaultProps = {
  breadcrumbLevels: [],
};

module.exports = Breadcrumb;
