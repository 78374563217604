function removeDuplicatedBreadcrumbs(breadcrumbLevels, textToRemove) {
  let isTextToRemoveFound = false;
  const result = [];

  breadcrumbLevels.slice().reverse().forEach(breadcrumbLevel => {
    if (breadcrumbLevel.text !== textToRemove) {
      result.push(breadcrumbLevel);
      return;
    }

    if (!isTextToRemoveFound) {
      result.push(breadcrumbLevel);
      isTextToRemoveFound = true;
    }
  });

  return result.reverse();
}

function replaceBreadcrumbWithDots(breadcrumbLevels) {
  const breadcrumb = breadcrumbLevels.map(breadcrumbLevel => ({ ...breadcrumbLevel }));

  if (breadcrumb.length > 3) {
    breadcrumb.splice(0, breadcrumb.length - 4);
    breadcrumb[0].text = '...';
  }

  return breadcrumb;
}

module.exports = {removeDuplicatedBreadcrumbs, replaceBreadcrumbWithDots};
