/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any, bool } = require('prop-types');
const { connect } = require('react-redux');

const Title = require('../components/Title');
const Bill = require('./Bill');

const Payment = ({ header, bill, toBeAgreed }) => {
  const baseClass = 'widget-payment';

  return (
    <div className={baseClass}>
      <Title {...header} />

      <Bill {...bill} toBeAgreed={toBeAgreed} />
    </div>
  );
};

Payment.propTypes = {
  header: objectOf(any).isRequired,
  bill: objectOf(any).isRequired,
  toBeAgreed: bool,
};
Payment.defaultProps = {
  toBeAgreed: false,
};

const mapStateToProps = ({ widgetDetails: { payment } }) => ({
  ...payment,
});

module.exports = connect(
  mapStateToProps,
  {},
)(Payment);
