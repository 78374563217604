const React = require('react');
const { node } = require('prop-types');

const { useSearchReducer } = require('./reducer');

const SearchContext = React.createContext();
const SearchDispatch = React.createContext();

const { useContext } = React;

const SearchProvider = ({ children }) => {
  const [state, dispatch] = useSearchReducer();

  return (
    <SearchContext.Provider value={state}>
      <SearchDispatch.Provider value={dispatch}>
        {children}
      </SearchDispatch.Provider>
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: node.isRequired,
};

const useSearchState = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('UseSearchState must be used within a provider');
  }

  return context;
};

const useSearchDispatch = () => {
  const dispatch = useContext(SearchDispatch);
  if (dispatch === undefined) {
    throw new Error('useSearchDispatch must be used within a provider');
  }

  return dispatch;
};

module.exports = {
  SearchProvider,
  useSearchState,
  useSearchDispatch,
};
