const React = require('react');

const LayoutContext = React.createContext(null);
const LayoutDispatch = React.createContext(null);

const LayoutReducer = (state, action) => {
  switch (action.type) {
    case 'setLayoutData':
      return {
        ...state,
        ...action.data,
      };
    default:
      throw new Error('Failed mapping LayoutReducer action');
  }
};
const LayoutProvider = ({ children, value }) => {
  const [state, dispatch] = React.useReducer(LayoutReducer, value);
  return (
    <LayoutContext.Provider value={state}>
      <LayoutDispatch.Provider value={dispatch}>
        {children}
      </LayoutDispatch.Provider>
    </LayoutContext.Provider>
  );
};

const useLayoutState = () => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('UseLayoutState must be used within a provider');
  }

  return context;
};

const useLayoutDispatch = () => {
  const dispatch = React.useContext(LayoutDispatch);
  if (dispatch === undefined) {
    throw new Error('useLayoutDispatch must be used within a provider');
  }

  return dispatch;
};

module.exports = {
  LayoutProvider,
  useLayoutState,
  useLayoutDispatch,
  LayoutContext,
};
