/**
 * Module dependencies
 */
const React = require('react');
// const { string, bool, arrayOf, node, oneOfType } = require('prop-types');

const ProductSummary = require('../Product/Summary');
const PaymentBill = require('../Payment/Bill');

const PackageDetail = ({ pack }) => (
  <div className="widget-package-detail">
    <p className={`widget-package-detail__title widget-package-detail__title--${pack.status}`}>{pack.title}</p>
    <ul className="widget-package-detail__list">
      {pack.items.map((item) => (
        <ProductSummary key={`product-summary-${item.title}`} data={item} />
      ))}
    </ul>
    {pack.bill && <PaymentBill {...pack.bill} />}
  </div>
);

PackageDetail.propTypes = {};
PackageDetail.defaultProps = {};

module.exports = PackageDetail;
