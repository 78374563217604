/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any, bool } = require('prop-types');
const classNames = require('classnames');

const Amount = ({ data, installments, interest, isTotal, children }) => {
  if (!data && !children) {
    return null;
  }
  const { symbol, fraction = '0', cents = '00', currency_id: currency } = data;
  const amount = fraction;

  return (
    <div
      className={classNames('purchase-amount', {
        'purchase-amount--total': isTotal,
      })}
    >
      {installments && <span className="purchase-amount__installments">{installments}</span>}

      {Boolean(Object.keys(data).length) && [
        <span key="purchase-amount__symbol" className="purchase-amount__symbol" content={currency}>
          {symbol}
        </span>,
        <span key="purchase-amount__fraction" className="purchase-amount__fraction">
          {amount}
        </span>,
        <span key="purchase-amount__cents" className="purchase-amount__cents">
          {cents}
        </span>,
      ]}

      {installments && interest && <span className="purchase-amount__interest">{interest}</span>}

      {children}
    </div>
  );
};

Amount.propTypes = {
  data: objectOf(any),
  isTotal: bool,
};
Amount.defaultProps = {
  data: {},
  isTotal: false,
};

module.exports = Amount;
