/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');

const Pago = ({ status }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <defs>
      <circle id="pago-a" cx="24" cy="24" r="24" />
      <rect id="pago-c" width="16" height="16" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="pago-b" fill="#fff">
          <use xlinkHref="#pago-a" />
        </mask>
        <use fill="#FFF" xlinkHref="#pago-a" />
        <g fill="#3483FA" fillRule="nonzero" mask="url(#pago-b)">
          <path d="M23.348 28.88c-1.319-.155-2.308-.698-3.002-1.439l.91-1.26a4.028 4.028 0 0 0 2.954 1.288c1.344 0 1.876-.658 1.876-1.274 0-.84-.994-1.092-2.128-1.386-1.526-.378-3.318-.826-3.318-2.8 0-1.358 1.056-2.444 2.708-2.682v-1.276h1.5v1.278c1.09.14 1.992.573 2.694 1.252l-.938 1.218c-.728-.728-1.722-1.064-2.702-1.064-.966 0-1.582.462-1.582 1.162 0 .728.952.966 2.058 1.246 1.54.392 3.374.868 3.374 2.898 0 1.4-.886 2.618-2.904 2.846v1.164h-1.5V28.88zm.75 6.523c-6.241 0-11.301-5.06-11.301-11.301 0-6.241 5.06-11.301 11.301-11.301 6.241 0 11.301 5.06 11.301 11.301 0 6.241-5.06 11.301-11.301 11.301zm0-1.5c5.413 0 9.801-4.388 9.801-9.801s-4.388-9.801-9.801-9.801-9.801 4.388-9.801 9.801 4.388 9.801 9.801 9.801z" />
        </g>
      </g>
      <g transform="translate(34 34)">
        <mask id="pago-d" fill="#fff">
          <use xlinkHref="#pago-c" />
        </mask>
        {status === 'success' && (
          <g mask="url(#pago-d)">
            <path fill="#00A650" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.788 9.396l4.577-4.577 1.028 1.029-5.605 5.605-3.181-3.181 1.028-1.029z"
            />
          </g>
        )}
        {status === 'warning' && (
          <g mask="url(#pago-d)">
            <path fill="#F73" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
        {status === 'error' && (
          <g mask="url(#pago-d)">
            <path fill="#F23D4F" d="M-.914-.457h18v17h-18z" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03 3.879h1.94l-.243 5.333H7.273L7.03 3.88zM8 10.182a.97.97 0 1 1 0 1.94.97.97 0 0 1 0-1.94z"
            />
          </g>
        )}
      </g>
    </g>
  </svg>
);

Pago.propTypes = {
  status: string,
};
Pago.defaultProps = {
  status: '',
};

module.exports = Pago;
