/* eslint-disable no-unused-expressions */
/* eslint-env browser */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { ImageProvider } = require('nordic/image');
const { I18n } = require('nordic/i18n');
const { Provider } = require('react-redux');
const { LayoutProvider } = require('../utils/context/layout');
const { GlobalProviders } = require('../utils/context/globalProviders');
const store = require('../redux/store');
const LayoutWrapper = require('../layouts/wrapper');

module.exports = function startApp(Component) {
  const preloadedProps = window.__PRELOADED_STATE__;
  const { translations, reduxStore, platform, baseURL, user, locale } = preloadedProps;
  const { siteId, id: business } = platform;
  const i18n = new I18n({ translations });

  if (business === 'MP' && siteId === 'MLB' && baseURL === '/home' && !user) {
    const linksToAdd = [
      {
        text: 'Ouvidoria',
        href: 'https://www.mercadopago.com.br/ajuda/4111',
      },
      {
        text: 'Informações Adicionais',
        href: 'https://www.mercadopago.com.br/informacoes-adicionais',
      },
    ];
    linksToAdd.forEach((link) => {
      const newLink = document.createElement('a');
      newLink.innerText = link.text;
      newLink.className = 'nav-section-mp__nav-link';
      newLink.setAttribute('href', link.href);
      document.querySelector(
        'section.nav-section-mp--about > nav.nav-section-mp__nav',
      )
      && document
        .querySelector(
          'section.nav-section-mp--about > nav.nav-section-mp__nav',
        )
        .appendChild(newLink);
    });
  }

  hydrateRoot(
    document.getElementById('root-app'),
    <Provider store={store(reduxStore)}>
      <GlobalProviders
        value={{
          i18n,
          platform: preloadedProps.platform,
          device: preloadedProps.device,
          user: preloadedProps.user,
          urlState: preloadedProps.urlState,
          flags: preloadedProps.flags,
          locale,
        }}
      >
        <ImageProvider prefix={preloadedProps.imagesPrefix}>
          <LayoutProvider value={preloadedProps.pageWrapperData}>
            <LayoutWrapper {...preloadedProps} verdiChatLink={preloadedProps.verdiChatLink}>
              <Component {...preloadedProps} />
            </LayoutWrapper>
          </LayoutProvider>
        </ImageProvider>
      </GlobalProviders>
    </Provider>,
  );
};
