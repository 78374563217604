/**
 * Module dependencies
 */
const React = require('react');
const { arrayOf, object, objectOf, any, string, bool } = require('prop-types');
const classNames = require('classnames');

const Method = require('./Method');
const BillSection = require('../Payment/BillSection');

const Bill = ({ chargeStatus, title, charges, expenses, total, hasTitle }) => {
  const hasChargeStatus = Boolean(chargeStatus && chargeStatus.length);
  return (
    <div className="payment-bill">
      {hasChargeStatus && (
        <div className="payment-bill__status-cards">
          {chargeStatus.map((cs) => (
            <Method key={`charge-status-${cs.title}`} {...cs} />
          ))}
        </div>
      )}

      {hasTitle && <p className="payment-bill__subtitle">{title}</p>}

      <div
        className={classNames('payment-bill__table', {
          'payment-bill__table--without-title': !hasTitle && hasChargeStatus,
        })}
      >
        {charges && <BillSection items={charges} textOverflow />}
        {expenses && <BillSection items={expenses} />}
        {total && <BillSection items={[total]} isTotal separator={Boolean(expenses || charges)} />}
      </div>
    </div>
  );
};
Bill.propTypes = {
  chargeStatus: arrayOf(object),
  title: string,
  charges: arrayOf(object),
  expenses: arrayOf(object),
  total: objectOf(any),
  hasTitle: bool,
};
Bill.defaultProps = {
  chargeStatus: [],
  title: '',
  charges: [],
  expenses: [],
  total: {},
  hasTitle: false,
};

module.exports = Bill;
