/**
 * Module dependencies
 */
const React = require('react');
const { string, arrayOf, object } = require('prop-types');

const ProductSummary = require('../Product/Summary');

const SellDetail = ({ title, status, items }) => (
  <div className="widget-sell-detail">
    <p className={`widget-sell-detail__title widget-sell-detail__title--${status}`}>{title}</p>

    <ul className="widget-sell-detail__list">
      {items.map(item => (
        <ProductSummary data={item} />
      ))}
    </ul>
  </div>
);

SellDetail.propTypes = {
  title: string.isRequired,
  status: string.isRequired,
  items: arrayOf(object),
};
SellDetail.defaultProps = {
  items: [],
};

module.exports = SellDetail;
