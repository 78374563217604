/**
 * Module dependencies
 */
const React = require('react');
const { string, objectOf, any } = require('prop-types');
const PaymentIcon = require('../../../PaymentIcon');

const getTitle = (description) => {
  if (description && description.title && description.title.text) {
    return description.title.text;
  }

  return '';
};

const getDescription = (description) => {
  let response = '';
  if (description && description.description && description.description.text) {
    response = description.description.text;
  }

  if (typeof response !== 'string' && response.key === 'address_line') {
    response = response.value;
  }

  return response;
};

const removeHTML = (text = '') => text.replace(/<[^>]*>?/gm, '');

const ShippingDetails = ({ description, receiver }) => {
  const descriptionText = getDescription(description);
  const titleText = getTitle(description);

  if (!descriptionText && !titleText) {
    return null;
  }

  return (
    <div className="widget-shipping-details">
      <PaymentIcon name="location" />
      <div className="widget-shipping-details__detail">
        <p className="widget-shipping-details__title">{removeHTML(titleText)}</p>
        <p className="widget-shipping-details__subtitle">{removeHTML(descriptionText)}</p>
        <p className="widget-shipping-details__subtitle">{removeHTML(receiver)}</p>
      </div>
    </div>
  );
};

ShippingDetails.propTypes = {
  description: objectOf(any),
  receiver: string,
};
ShippingDetails.defaultProps = {
  description: {},
  receiver: '',
};

module.exports = ShippingDetails;
