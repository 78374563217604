const CONTRACT_TAG = 'contract';
const RESERVATION_TAG = 'reservation';

const getVerticalStatus = (pack, vertical) => Boolean(pack && pack.tags && pack.tags.length && pack.tags.includes(vertical));

const verticalsStatus = {
  [RESERVATION_TAG]: pack => getVerticalStatus(pack, RESERVATION_TAG),
  [CONTRACT_TAG]: pack => getVerticalStatus(pack, CONTRACT_TAG),
};

const getActiveClassiVerticals = (purchaseObj) => {
  if (purchaseObj && purchaseObj.packs && Array.isArray(purchaseObj.packs) && purchaseObj.packs.length) {
    const { tags } = purchaseObj.packs[0];
    if (tags && Array.isArray(tags) && tags.length) {
      return [CONTRACT_TAG, RESERVATION_TAG].reduce(
        (acum, it) => ({
          ...acum,
          [it]: tags.includes(it),
        }),
        {},
      );
    }
  }
  return {};
};
const getPurchaseTitle = (classiVertical, i18n) => {
  if (classiVertical.reservation) {
    return i18n.gettext('Detalle de la reserva');
  }
  if (classiVertical.contract) {
    return i18n.gettext('Detalle de contratación');
  }
  return i18n.gettext('Detalle de la compra');
};

const getPurchaseSubtitle = (classiVertical, purchaseDate, i18n) => {
  if (classiVertical.reservation) {
    return i18n.gettext('Reservaste el {0}', [purchaseDate]);
  }
  if (classiVertical.contract) {
    return i18n.gettext('Contrataste el {0}', [purchaseDate]);
  }
  return i18n.gettext('Compraste el {0}', [purchaseDate]);
};

const getText = (obj = {}, key) => obj[key] || '';
const getDeepText = (obj = {}, expression) => {
  if (!(obj && expression && typeof expression === 'string')) {
    throw new Error('both object and expression args are required');
  }

  return expression
    .trim()
    .split('.')
    .reduce((prev, curr) => {
      if (prev) {
        return prev[curr] || '';
      }
      return '';
    }, obj);
};

const getPurchasePacks = (purchasePackages = {}) => purchasePackages.packages.map((pack = {}) => ({
  shipping_id: pack.shipping_id,
  heading: getText(pack.heading, 'title'),
  title: getText(pack.title, 'text'),
  status: getText(pack.status, 'type'),
  subtitle: getText(pack.subtitle, 'text'),
  items: pack.items.map((product = {}) => ({
    image: getText(product.image, 'thumbnail'),
    title: getText(product.title, 'label'),
    amount: product.price,
    quantity: getText(product.quantity, 'text'),
  })),
}));

const formatPaymentMethod = (paymentMethod = {}) => ({
  title: paymentMethod.title.text,
  subtitle: paymentMethod.subtitle ? paymentMethod.subtitle.text : '',
  icon: paymentMethod.brand ? paymentMethod.brand.icon : '',
  status_icon: paymentMethod.brand ? paymentMethod.brand.status_icon : '',
  first_payment: paymentMethod.first_payment,
  second_payment: paymentMethod.second_payment,
  payment_status: paymentMethod.payment_status,
  failure: paymentMethod.payment_status_failure,
});

const getPurchaseBill = (purchase) => {
  // TODO: Si alguno de los pagos tiene intereses, agregar en expenses un campo "Subtotal" con la suma de cada gasto
  const { paymentDetails, paymentMethods = [] } = purchase;
  return {
    title: paymentDetails.title.text,
    expenses: paymentDetails.expenses,
    payments: paymentDetails.payments ? paymentDetails.payments.sort((a, b) => (a.label < b.label) * -1) : [],
    refunds: paymentDetails.refund,
    total: paymentDetails.total,
    paymentMethods: paymentMethods.map(formatPaymentMethod),
    purchase,
  };
};
const getShippingAddress = shippingAddress => ({
  title: getDeepText(shippingAddress, 'title.text'),
  description: shippingAddress,
  icon: getDeepText(shippingAddress, 'brand.icon'),
  receiver: getDeepText(shippingAddress, 'receiver.text'),
});

const getShippingText = (purchase, orderDetail) => {
  let shippingText;
  if (purchase.shippingAddress) {
    shippingText = getShippingAddress(purchase.shippingAddress);
  } else if (orderDetail) {
    const ship = orderDetail.shipping;
    shippingText = {
      description: {
        title: { text: '' },
        description: { text: '' },
      },
      label: ship.messages.label,
      text: ship.messages.message.text || '',
      icon: ship.messages.label_ico || '',
    };
  }
  return shippingText || {};
};

const getPackDetailFromPurchase = (packages, packId) => {
  const pack = packages.find(p => p.pack_id === packId);
  let response = {};
  if (pack) {
    response = {
      heading: getDeepText(pack, 'heading.text'),
      title: getDeepText(pack, 'title.text'),
      subtitle: getDeepText(pack, 'subtitle.text'),
      status: getDeepText(pack, 'status.type'),
      items: pack.items.map(i => ({
        image: getDeepText(i, 'image.thumbnail'),
        title: getDeepText(i, 'title.label'),
        amount: i.price,
        quantity: getDeepText(i, 'quantity.text'),
      })),
    };
  }
  return response;
};

const getTextsForRenderItem = (i18n, item) => {
  const response = { ...item };
  const id = item.id.substring(3, item.id.toString().length).toUpperCase();
  response.idText = i18n.gettext('# {0}', [id]);

  switch (item.status) {
    case 'active':
      response.statusText = i18n.gettext('Activa');
      break;
    case 'closed':
      response.statusText = i18n.gettext('Finalizada');
      break;
    case 'paused':
      response.statusText = i18n.gettext('Pausada');
      break;
    case 'not_yet_active':
      response.statusText = i18n.gettext('Programada');
      break;
    case 'payment_required':
      response.statusText = i18n.gettext('Para revisar');
      break;
    case 'under_review':
      response.statusText = i18n.gettext('Para revisar');
      break;
    case 'inactive':
      response.statusText = i18n.gettext('Finalizada');
      break;
    default:
      break;
  }

  switch (item.listing_type_id) {
    case 'gold_premium':
      response.listingTypeText = i18n.gettext('Oro Premium');
      break;
    case 'gold_pro':
      response.listingTypeText = i18n.gettext('Premium');
      break;
    case 'gold_special':
      response.listingTypeText = i18n.gettext('Clásica');
      break;
    case 'gold':
      response.listingTypeText = i18n.gettext('Oro');
      break;
    case 'silver':
      response.listingTypeText = i18n.gettext('Plata');
      break;
    case 'bronze':
      response.listingTypeText = i18n.gettext('Bronce');
      break;
    case 'free':
      response.listingTypeText = i18n.gettext('Gratuita');
      break;
    default:
      break;
  }

  return response;
};

const getFraction = (base, locale) => {
  const fraction = Math.trunc(base);
  const baseLocale = locale.replace('_', '-');
  return fraction.toLocaleString(baseLocale);
};

const getCents = (base, decimalPlaces) => {
  const fraction = Math.trunc(base);
  let response = (base - fraction).toString();
  response = response.split('.');
  return response.length > 1 ? response[1].slice(0, decimalPlaces) : '00';
};

const parseBillCharges = (packageDetail) => {
  const salesDetails = packageDetail.templates.sale_details;
  const paymentMethods = packageDetail.templates.payment_method;

  return {
    title: getDeepText(salesDetails, 'title.text'),
    chargeStatus: paymentMethods ? paymentMethods.map(formatPaymentMethod) : [],
    charges: salesDetails && salesDetails.sales,
    expenses: salesDetails && salesDetails.charges,
    total: salesDetails && salesDetails.total,
  };
};

const getShipmentText = (ship) => {
  if (!ship) {
    return null;
  }
  let title = '';
  let description = '';

  if (ship.fields) {
    ship.fields.forEach((it) => {
      if (it.key === 'address_line') {
        if (/<br>/.test(it.value)) {
          const aux = it.value.split('<br>');
          [title, description] = aux;
        } else {
          description = it;
        }
      }
    });
  }

  return {
    description: {
      title: { text: title },
      description: { text: description },
    },
    label: getDeepText(ship, 'header'),
    text: getDeepText(ship, 'subheader'),
    icon: getDeepText(ship, 'label_ico'),
    status: getDeepText(ship, 'label_style'),
  };
};

module.exports = {
  verticalsStatus,
  getActiveClassiVerticals,
  getPurchaseTitle,
  getPurchaseSubtitle,
  getPurchasePacks,
  getDeepText,
  getPurchaseBill,
  getShippingText,
  getShippingAddress,
  getPackDetailFromPurchase,
  getTextsForRenderItem,
  getFraction,
  getCents,
  formatPaymentMethod,
  parseBillCharges,
  getShipmentText,
};
