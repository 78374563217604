/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

/**
 * Define actions
 */
const reducer = 'profiles';
const UPDATE = `${reducer}/UPDATE`;

/**
 * Create actions
 */

const triggerUpdate = createAction(UPDATE);

/**
 * Export actions
 */
module.exports = {
  UPDATE,
  triggerUpdate,
};
