const React = require('react');
const { bool, string } = require('prop-types');
const { Pill } = require('@andes/badge');

const MelilabTag = ({ isMercadoPago, scope }) => {
  const color = isMercadoPago ? 'orange' : 'accent';
  const mercadoPagoClassName = isMercadoPago ? 'meliLab__tag__mp' : '';
  return (
    <div className={`meliLab__tag ${mercadoPagoClassName}`}>
      <Pill contentType="text" color={color}>
        {scope}
      </Pill>
    </div>
  );
};

MelilabTag.propTypes = {
  isMercadoPago: bool,
  scope: string,
};

MelilabTag.defaultProps = {
  isMercadoPago: false,
  scope: '',
};

module.exports = MelilabTag;
