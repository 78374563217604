/**
 * Status Types
 */
const statusTypes = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const webKitConstants = {
  CONNECT: 'connect',
  METHOD_SUBSCRIBE_EVENT: 'subscribe_event',
  METHOD_DISPATCH_EVENT: 'dispatch_event',
  TOPIC_NATIVE_CHAT: 'native_chat',
  TOPIC_NATIVE_RETURN_CHAT: 'native_return_chat',
  NATIVE_CHAT_SHOW: 'show',
  NATIVE_CHAT_DISMISS: 'dismiss',
};

const chatDataInitialState = {
  status: 'PENDING',
  isChatActive: false,
  userId: '',
  chatId: 0,
  caseNumber: '',
  site: '',
  nickname: '',
  id: 0,
  formReference: '',
  chatReference: '',
};

module.exports = {
  statusTypes, webKitConstants, chatDataInitialState,
};
