const semver = require('semver');
const { WEBVIEW_BASE, WEBVIEW_CUSTOM_PORTAL_VALID, MLHelpBaseDeeplink, MPHelpBaseDeeplink, SEARCH_VIEW_PATH, HELP_VIEW_PATH_ES, HELP_VIEW_PATH_PT, MERCHANTS_CREDITS_SIMULATOR_URL } = require('./constants');
const { Android, SmartPOS } = require('./mobile/constants');
const { createDeeplink } = require('./mobile/nativeActions');
const logger = require('nordic/logger');

const log = logger('App Utils');

const domainExtension = {
  MLA: '.com.ar',
  MLB: '.com.br',
  MLC: '.cl',
  MLM: '.com.mx',
  MCO: '.com.co',
  MLV: '.com.ve',
  MGT: '.com.gt',
  MPE: '.com.pe',
  MEC: '.com.ec',
  MPY: '.com.py',
  MLU: '.com.uy',
  MPA: '.com.pa',
  MCR: '.co.cr',
  MRD: '.com.do',
  MNI: '.com.ni',
  MHN: '.com.hn',
  MSV: '.com.sv',
  MBO: '.com.bo',
  CBT: '.com',
};

const camelCaseGA = obj => Object.keys(obj).reduce((acum, key) => {
  if (key.endsWith('Id')) {
    const newkey = key.replace('Id', 'ID');
    return { ...acum, [newkey]: obj[`${key}`] };
  }
  return { ...acum, [key]: obj[`${key}`] };
}, {});

const getStyleFile = (type, business = 'ML', webView = false) => {
  const businessTag = business === 'MP' ? '-mp' : '';
  const device = webView ? '-mobile' : '';
  return `${type}${device}${businessTag}.css`;
};

const hasYoshiApp = (device, prefix) => {
  if (device.nativeApp && device.nativeApp.name === 'MercadoEnviosExtra') {
    if (device.nativeApp.os === 'Android') {
      return semver.gte(device.nativeApp.version, '2.22.0');
    }
  }
  if (device.nativeApp && device.nativeApp.name === 'MercadoLibre') {
    if (device.nativeApp.os === 'Android') {
      const customPortal = prefix && WEBVIEW_CUSTOM_PORTAL_VALID.find(x => prefix === x.prefix);
      return (customPortal) ? semver.gte(device.nativeApp.version, customPortal.version) : semver.gte(device.nativeApp.version, '10.20.4');
    }
    if (device.nativeApp.os === 'iOS') {
      return semver.gte(device.nativeApp.version, '10.95.0');
    }
  }

  if (device.nativeApp && device.nativeApp.name === 'MercadoPago') {
    return semver.gte(device.nativeApp.version, '2.146.0');
  }

  return false;
};

const myMLUrl = (siteId = 'MLA') => {
  if (siteId === 'MLB') {
    return 'https://myaccount.mercadolivre.com.br';
  }
  return `https://myaccount.mercadolibre${domainExtension[siteId.toString()]}`;
};

const myMLSummaryUrl = (siteId, native) => (native ? 'meli://my_account' : `${myMLUrl(siteId)}/summary`);

const getQueryString = (appInfo) => {
  const params = (appInfo && appInfo.app && appInfo.appParams) || {};
  const queryString = Object.entries(params)
    .reduce((prev, [key, value]) => `${prev}&${key}=${value}`, '');

  return queryString ? `?${queryString}` : '';
};

const resolveLinkLegacy = (baseUrl, content, appInfo, suffix = '') => {
  if (content.type === 'REDIRECT') {
    return content.redirect_url;
  }

  let url = '';
  const queryString = getQueryString(appInfo);

  if (['RENDER', 'FOLDER'].includes(content.type)) {
    if (queryString) {
      url = `${baseUrl}/${appInfo.app}/nextLevel/${content.content_id}${queryString}`;
    } else {
      url = content.metadata && content.metadata.url_friendly
        ? `${baseUrl}/${content.metadata.url_friendly.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}_${content.content_id}`
        : `${baseUrl}/${content.content_id}`;
    }
  }

  if (content.type === 'SHOW_CONTACT') {
    url = `${baseUrl}/content/${content.content_id}/contact/${content.channels.problem_id}${queryString}`;
  }

  return `${url}${suffix}`;
};

const resolveLink = (baseUrl, content, appInfo, suffix = '', device, platform, features = {
  linkAdapters: [],
}) => {
  let resolvedLink = resolveLinkLegacy(baseUrl, content, appInfo, suffix);

  if (features.linkAdapters && Array.isArray(features.linkAdapters) && features.linkAdapters.length > 0) {
    features.linkAdapters.forEach((linkAdapter) => {
      resolvedLink = linkAdapter(resolvedLink, content);
    });
  }

  const isNativeMobile = device && device.nativeApp;

  return (isNativeMobile && platform) ? createDeeplink(resolvedLink, `https://www.${platform.domain}`, platform.id) : resolvedLink;
};

const buildLinkToRedirect = (device, business, caseNumber, caseUrl) => {
  const businessPrefix = business === 'MP' ? 'mercadopago' : 'meli';
  const buildedCaseUrl = `${caseUrl}/${caseNumber}`;

  if (device.webView && (business === 'ML' || (business === 'MP' && device.nativeApp?.os?.toLowerCase() === 'ios'))) {
    return `${businessPrefix}://cx/support/cases?uri=${encodeURIComponent(buildedCaseUrl)}`;
  }

  return buildedCaseUrl;
};

const contextToUrl = (context) => {
  const response = Object.keys(context).map((key) => {
    const value = context[key];
    if (['number', 'string'].includes(typeof value)) {
      return `${key}=${value}`;
    }
    return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
  });
  return response.join('&');
};

const getMobileOsParam = osName => (osName === 'android' || osName === 'ios' ? `osname=${osName}` : '');

const getMobileOsVersionParam = osVersion => (validVersionRegex.test(osVersion) ? `osversion=${osVersion}` : '');

// Expected format is "n.n.n" where "n" is an int number.
const validVersionRegex = new RegExp('^([0-9]+\\.){2}[0-9]+$');

const parseTags = tags => Object.keys(tags).reduce((acum, key) => {
  const val = tags[key.toString()];
  return {
    ...acum,
    [key]: typeof val === 'object' ? JSON.stringify(val) : val,
  };
}, {});

const stringifyValues = obj => {
  const resultObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    resultObj[key] = typeof value !== 'undefined' ? value.toString() : '';
  });
  return resultObj;
};

const formatCurrency = (symbol, integerPart, centPart, thousandSeparator, decimalSeparator) => {
  const integerPartSeparated = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
  return `${symbol} ${integerPartSeparated}${centPart ? decimalSeparator : ''}${centPart}`;
};

const questionsUrl = (siteId = 'MLA') => {
  if (siteId === 'MLB') {
    return 'https://questions.mercadolivre.com.br/buyer';
  }
  return `https://questions.mercadolibre${domainExtension[siteId.toString()]}/buyer`;
};

const getUrlResolver = (homeUrl, baseUrl, appProtocol, webviewUrl) => (path = '') => {
  const url = `${homeUrl}${baseUrl}${path}`;

  if (appProtocol) {
    return `${appProtocol}://${webviewUrl}${encodeURIComponent(url)}`;
  }

  return url;
};

const resolveBaseUrl = (siteId, portalPrefix) => {
  const baseUrlBySite = {
    MLB: '/ajuda',
    CBT: '/help',
    default: '/ayuda',
    '/developers/es/support': '/support',
    '/developers/en/support': '/support',
    '/developers/pt/support': '/support',
    '/centro-de-contenidos': '/centro-de-ayuda',
    '/central-de-conteudos': '/central-de-ajuda',
    '/centro-de-ayuda': '/centro-de-ayuda',
    '/central-de-ajuda': '/central-de-ajuda',
  };
  return baseUrlBySite[portalPrefix] || baseUrlBySite[siteId] || baseUrlBySite.default;
};

const getUrls = (req) => {
  const { hostname, platform, device, scope, headers, urlToReturn, originalUrl } = req;
  const prefix = headers && headers['x-portal-prefix'] ? `/${headers['x-portal-prefix']}` : '';
  const hasYoshi = hasYoshiApp(device, prefix);
  const isWebView = device.webView;
  const baseUrl = `${prefix.replace('/support', '')
    .replace('/centro-de-contenidos', '')
    .replace('/central-de-conteudos', '')
    .replace('/central-de-ayuda', '')
    .replace('/central-de-ajuda', '')
    .replace('mercado-', '')}${resolveBaseUrl(platform.siteId, prefix)}`;
  const port = scope === 'local' ? ':8443' : '';
  const homeUrl = `https://${hostname}${port}`;
  const nativeAppName = device && device.nativeApp && device.nativeApp.name && device.nativeApp.name.toLowerCase();
  const appProtocol = isWebView && hasYoshi && WEBVIEW_BASE[nativeAppName];

  const resolveCasesUrl = getUrlResolver(homeUrl, '/cases', appProtocol, 'cx/support/cases?uri=');
  const resolveHelpUrl = getUrlResolver(homeUrl, baseUrl, appProtocol, 'cx/help?url=');
  const resolveCreditsUrl = getUrlResolver(homeUrl, '/credits', appProtocol, 'credits-merchant-enrollment?url=');

  const resolveUrlByFormId = (formId) => {
    const urlByFormId = {
      8616: resolveCreditsUrl(MERCHANTS_CREDITS_SIMULATOR_URL),
    };

    return urlByFormId[formId] || undefined;
  };

  return {
    hostname,
    baseUrl,
    homeUrl,
    helpUrl: resolveHelpUrl(),
    casesUrl: resolveCasesUrl(),
    resolveHelpUrl,
    resolveCasesUrl,
    resolveUrlByFormId,
    urlToReturn,
    originalUrl,
    headers,
  };
};
const isWebMobile = (device) => device.type === 'mobile' && !device.webView;

const getPortalCss = (portalPrefix, homeConfig) => {
  const css = [];
  if (portalPrefix) {
    if (homeConfig) {
      const sections = homeConfig.getSections();
      const titleComponent = sections.sections ? sections.sections.find(s => s.component === 'SearchSection') : null;
      if (titleComponent && titleComponent.headerColor) {
        css.push(`.nav-header { background-color: ${titleComponent.headerColor} !important }`);
      }
      if (titleComponent && titleComponent.logo) {
        css.push(`.nav-logo { background-image: url(${titleComponent.logo}) !important; background-size: contain; }`);
        if (titleComponent.logoWidth) {
          css.push(`.nav-logo { width: ${titleComponent.logoWidth} !important }`);
          css.push('.nav-logo { height: 38px !important }');
          css.push(`.nav-logo { background-size: ${titleComponent.logoWidth} !important }`);
        }
        if (titleComponent.logoWebMobile) {
          css.push(`@media screen and (max-width: 1023px) { header .nav-logo { background-image: url(${titleComponent.logoWebMobile})!important; } }`);
        }
      }
    }
  }
  return css;
};
const getPortalFavicons = (portalPrefix, homeConfig) => {
  const favicons = [];
  if (portalPrefix) {
    if (homeConfig) {
      const sections = homeConfig.getSections();
      const titleComponent = sections.sections ? sections.sections.find(s => s.component === 'SearchSection') : null;
      if (titleComponent && titleComponent.favicon) {
        favicons.push({ href: titleComponent.favicon });
      }
    }
  }
  return favicons;
};
const getPortalType = (siteId, portalPrefix) => {
  let type = 'lite';
  if (siteId === 'CBT') {
    type = 'full';
  } else if (portalPrefix) {
    type = 'clean';
  }
  return type;
};
const loginUrl = (bu, siteId, isNativeMobile, callbackUrl) => {
  const deepLinkPrefix = `${bu === 'ML' ? 'meli' : 'mercadopago'}://cx/help?url=`;
  const finalCallbackUrl = `${isNativeMobile ? deepLinkPrefix : ''}${callbackUrl}`;
  return `https://www.mercadoli${siteId === 'MLB' ? 'v' : 'b'}re.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=${bu}&go=${finalCallbackUrl}`;
};

const createDeeplinkForCustomPortal = (homeUrl, url, id) => {
  const baseDeeplink = id === 'ML' ? MLHelpBaseDeeplink : MPHelpBaseDeeplink;
  const absoluteURL = url?.startsWith('/') ? `${homeUrl}${url}` : url;
  const deeplink = `${baseDeeplink}${encodeURIComponent(absoluteURL)}`;
  return deeplink;
};

const isSearchResultView = (url) => {
  // TODO remove this when the rollout of the new search engine is complete
  const urlDecoded = decodeURIComponent(url);
  return urlDecoded.includes(SEARCH_VIEW_PATH);
};

const replaceQueryParam = (link, paramName, newValue) => {
  try {
    const url = new URL(link);
    url.searchParams.set(paramName, newValue);
    return url.toString();
  } catch (error) {
    log.error('Error replacing query param', { message: error.message, stack: error.stack, link, paramName, newValue });
    return link;
  }
};

const replaceMultipleQueryParams = (url, queryParams) => {
  let urlWithQueryParams = url;
  queryParams.forEach((queryParam) => {
    urlWithQueryParams = replaceQueryParam(urlWithQueryParams, queryParam.key, queryParam.value);
  });

  return urlWithQueryParams;
};

const getQueryParam = (url, paramKey) => {
  try {
    const urlWithQueryParams = url.split('?');
    const queryParams = urlWithQueryParams[1];

    if (!queryParams) return null;

    const params = new URLSearchParams(queryParams);
    return params.get(paramKey);
  } catch (error) {
    log.error('Error getting query param', { message: error.message, stack: error.stack, url, paramKey });
    return null;
  }
};

const isHelpView = (url) => {
  const urlDecoded = decodeURIComponent(url);
  return urlDecoded.includes(HELP_VIEW_PATH_ES || HELP_VIEW_PATH_PT);
};

const isAndroid = (nativeApp) => nativeApp && nativeApp.os?.toLowerCase() === Android;

const isSmartPOS = (nativeApp) => nativeApp?.name?.toLowerCase() === SmartPOS;

const isMobileSearchBtnDelegated = (
  isSearchBtnVisible,
  nativeApp,
  showNativeSearch,
  isSearchButtonV2Enabled,
  actualLocation,
  pageId,
) => {
  if (!isSearchBtnVisible && nativeApp && showNativeSearch && isSearchButtonV2Enabled && pageId === 'content') {
    return true;
  }
  if (!isSearchBtnVisible && nativeApp && showNativeSearch && !isSearchButtonV2Enabled && !(isSearchResultView(actualLocation))) {
    return true;
  }
  return false;
};

module.exports = {
  domainExtension,
  camelCaseGA,
  getStyleFile,
  resolveLink,
  buildLinkToRedirect,
  contextToUrl,
  hasYoshiApp,
  getMobileOsParam,
  getMobileOsVersionParam,
  parseTags,
  stringifyValues,
  myMLUrl,
  myMLSummaryUrl,
  formatCurrency,
  questionsUrl,
  getUrls,
  isWebMobile,
  getPortalCss,
  getPortalFavicons,
  getPortalType,
  loginUrl,
  createDeeplinkForCustomPortal,
  isSearchResultView,
  getQueryParam,
  replaceQueryParam,
  replaceMultipleQueryParams,
  isHelpView,
  isAndroid,
  isSmartPOS,
  isMobileSearchBtnDelegated,
};
