/**
 * Module dependencies
 */
const React = require('react');
// const { objectOf, any } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const ProductAttribute = ({ i18n, sizeAttribute, colorOneAttribute, colorTwoAttribute, two }) => (
  <div className="product-attribute">
    {colorOneAttribute && (
      <p className="product-attribute__color">
        {`${i18n.gettext('Color')}: `}
        <span className="product-attribute__color--strong">{sizeAttribute.name}</span>
        {Boolean(colorTwoAttribute && two) && <span className="product-attribute__color--strong">{two.name}</span>}
      </p>
    )}
    {sizeAttribute && (
      <p className="product-attribute__size">
        {`${i18n.gettext('Talle')}: `}
        <span className="product-attribute__size--strong">{sizeAttribute.name}</span>
      </p>
    )}
  </div>
);

ProductAttribute.propTypes = {};
ProductAttribute.defaultProps = {};

module.exports = injectI18n(ProductAttribute);
