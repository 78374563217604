/**
 * Module dependencies
 */
const React = require('react');
const { shape, func } = require('prop-types');
const { connect } = require('react-redux');
const injectI18n = require('nordic/i18n/injectI18n');
const classNames = require('classnames');

const Title = require('../components/Title');
const Collapsible = require('../components/Collapsible');
const ChargesBill = require('../Charges/Bill');
const Detail = require('./Detail');
const ShippingDetails = require('../Purchase/ShippingDetails');

const Sell = ({ i18n, header, shipping, items, isReservation, isContract, bill, pack, packDetail }) => {
  const baseClass = 'widget-sell';

  let title = '';
  let status = '';
  if (!isReservation && !isContract) {
    title = (shipping && shipping.label) || '';
    status = (shipping && shipping.status) || '';
  } else {
    title = (packDetail && packDetail.title && packDetail.title.text) || '';
    status = (packDetail && packDetail.status && packDetail.status.type) || '';
  }

  return (
    <div className={classNames([baseClass, 'widget-purchase'])}>
      <Title {...header} />

      <p className={`${baseClass}__package-title`}>{(pack && pack.title) || ''}</p>

      <Detail baseClass={baseClass} title={title} status={status} items={items} />

      {shipping && !isReservation && !isContract && <ShippingDetails {...shipping} />}

      {bill && (
        <Collapsible title={i18n.gettext('Cobro')} baseClass={baseClass}>
          <ChargesBill {...bill} />
        </Collapsible>
      )}
    </div>
  );
};

Sell.propTypes = {
  i18n: shape({ gettext: func.isRequired }).isRequired,
};
Sell.defaultProps = {};

const mapStateToProps = ({ widgetDetails }) => ({
  ...widgetDetails[widgetDetails.type],
});

const ConectedSell = injectI18n(
  connect(
    mapStateToProps,
    {},
  )(Sell),
);

module.exports = ConectedSell;
