/* eslint-disable global-require */
/**
 * Module dependencies
 */
const searchContents = (query, page, baseUrl) => {
  try {
    const domPageToken = document.querySelector('meta[name="page-token"]');
    const pageToken = domPageToken ? domPageToken.getAttribute('content') : '';
    const browserRestClient = require('../utils/restclient-browser')();

    return browserRestClient.get(`/search/faqs?q=${query}&page=${page}&baseUrl=${baseUrl}`, {
      headers: { 'CSRF-Token': pageToken },
    });
  } catch (e) {
    return {};
  }
};

const getSuggestions = (baseUrl) => {
  try {
    const domPageToken = document.querySelector('meta[name="page-token"]');
    const pageToken = domPageToken ? domPageToken.getAttribute('content') : '';
    const browserRestClient = require('../utils/restclient-browser')();

    return browserRestClient.get(`/search/suggest?baseUrl=${baseUrl}`, {
      headers: { 'CSRF-Token': pageToken },
    });
  } catch (e) {
    return {};
  }
};

module.exports = {
  searchContents,
  getSuggestions,
};
