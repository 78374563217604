/**
 * Module dependencies
 */
const React = require('react');
const CHANNEL_TYPES = require('./channels');
const { CLASS_NOT_CONTACT_IN_PROGRESS, CLASS_CONTACT_CALL_IN_PROGRESS, CLASS_CONTACT_CHAT_MELICHAT_PROGRESS, CLASS_CONTACT_CHAT_WHATSAPP_PROGRESS } = require('./constants');

const ContactInProgressIllustration = ({ channel, color }) => {
  switch (channel) {
    case CHANNEL_TYPES.none:
      return (
        <svg id={CLASS_NOT_CONTACT_IN_PROGRESS} data-testid={CLASS_NOT_CONTACT_IN_PROGRESS} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector1" d="M5 8.5H2.5C2.22386 8.5 2 8.72386 2 9V15C2 15.2761 2.22386 15.5 2.5 15.5H5V8.5ZM2.5 7C1.39543 7 0.5 7.89543 0.5 9V15C0.5 16.1046 1.39543 17 2.5 17H6.5V7H2.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector2" d="M18.9 8.5H21.4C21.6762 8.5 21.9 8.72386 21.9 9V15C21.9 15.2761 21.6762 15.5 21.4 15.5H18.9V8.5ZM21.4 7C22.5046 7 23.4 7.89543 23.4 9V15C23.4 16.1046 22.5046 17 21.4 17H17.4V7H21.4Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector3" d="M13.75 22.5L11.25 22.5C10.8358 22.5 10.5 22.1642 10.5 21.75C10.5 21.3358 10.8358 21 11.25 21L13.75 21C14.1642 21 14.5 21.3358 14.5 21.75C14.5 22.1642 14.1642 22.5 13.75 22.5ZM11.25 24C10.0074 24 9 22.9926 9 21.75C9 20.5074 10.0074 19.5 11.25 19.5L13.75 19.5C14.9926 19.5 16 20.5074 16 21.75C16 22.9926 14.9926 24 13.75 24L11.25 24Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector4" d="M15 21.25C17.3472 21.25 19.25 19.3472 19.25 17H20.75C20.75 20.1756 18.1756 22.75 15 22.75H14.5V21.25H15Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector5" d="M12 1.75C8.38512 1.75 5.5 4.57281 5.5 8L4 8C4 3.6952 7.60675 0.25 12 0.25C16.3933 0.249999 20 3.6952 20 8L18.5 8C18.5 4.57281 15.6149 1.75 12 1.75Z" />
        </svg>
      );
    case CHANNEL_TYPES.call:
      return (
        <svg id={CLASS_CONTACT_CALL_IN_PROGRESS} data-testid={CLASS_CONTACT_CALL_IN_PROGRESS} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="phone" d="M17.2479 10.9591L12.5475 9.39158L11.1982 10.7391C9.09029 10.5922 7.40602 8.90807 7.25877 6.80028L8.60827 5.45258L7.03865 0.75H2.99853C1.75589 0.75 0.748535 1.75736 0.748535 3V3.99989C0.748535 11.3174 6.68051 17.2493 13.998 17.2493H14.9979C16.2405 17.2493 17.2479 16.242 17.2479 14.9993V10.9591ZM11.4981 12.2496H11.8085L12.9519 11.1076L15.7479 12.04V14.9993C15.7479 15.4135 15.4121 15.7493 14.9979 15.7493H13.998C7.50894 15.7493 2.24854 10.4889 2.24854 3.99989V3C2.24854 2.58579 2.58432 2.25 2.99854 2.25H5.95797L6.89157 5.04708L5.74831 6.18882V6.49978C5.74831 9.67529 8.32257 12.2496 11.4981 12.2496Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="phone-signal" d="M2.30852 0.80738C2.13602 0.767949 1.98485 0.887827 1.95528 1.04236L1.8052 1.82683C1.77566 1.98126 1.86966 2.14041 2.03093 2.17789C2.73579 2.34174 3.76727 2.76873 4.62395 3.52818C5.48328 4.28998 6.1529 5.36924 6.18037 6.87553C6.18316 7.02823 6.30385 7.17218 6.47691 7.17218H7.24216C7.41408 7.17218 7.53946 7.02802 7.53703 6.86874C7.50672 4.8792 6.6043 3.43806 5.50797 2.46617C4.44813 1.52662 3.19447 1.00989 2.30852 0.80738ZM1.29235 3.80696C1.11712 3.76811 0.968002 3.893 0.941212 4.04704L0.804319 4.83413C0.777185 4.99015 0.874774 5.14466 1.0324 5.18137C1.76515 5.35203 2.85023 5.99508 3.25768 7.54954C3.29659 7.69802 3.44959 7.80962 3.61889 7.76537L4.3613 7.57134C4.52563 7.52839 4.61252 7.35965 4.57299 7.20622C4.01157 5.02716 2.43681 4.0607 1.29235 3.80696Z" />
        </svg>
      );
    case CHANNEL_TYPES.melichat:
      return (
        <svg id={CLASS_CONTACT_CHAT_MELICHAT_PROGRESS} data-testid={CLASS_CONTACT_CHAT_MELICHAT_PROGRESS} width="62" height="65" viewBox="0 0 62 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="message" d="M0.0673828 42.2479C0.0673828 46.9078 3.84498 50.6854 8.50488 50.6854H30.1487L50.6877 64.3765V50.6854H53.4955C58.1554 50.6854 61.933 46.9078 61.933 42.2479V8.50293C61.933 3.84303 58.1554 0.0654297 53.4955 0.0654297H8.50488C3.84498 0.0654297 0.0673828 3.84303 0.0673828 8.50293V42.2479ZM53.4955 45.0604H45.0627V53.8668L31.8517 45.0604H8.50488C6.95158 45.0604 5.69238 43.8012 5.69238 42.2479V8.50293C5.69238 6.94963 6.95158 5.69043 8.50488 5.69043H53.4955C55.0488 5.69043 56.308 6.94963 56.308 8.50293V42.2479C56.308 43.8012 55.0488 45.0604 53.4955 45.0604Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="line-one" d="M0,0h36v6h-36Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="line-two" d="M0,0h24v6h-24Z" />
        </svg>
      );
    case CHANNEL_TYPES.whatsapp:
      return (
        <svg id={CLASS_CONTACT_CHAT_WHATSAPP_PROGRESS} data-testid={CLASS_CONTACT_CHAT_WHATSAPP_PROGRESS} width="200" height="200" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} d="M378.853,294.202c-0.997-0.479-38.324-18.859-44.956-21.246c-2.708-0.972-5.609-1.922-8.694-1.922 c-5.04,0-9.274,2.512-12.572,7.446c-3.729,5.542-15.016,18.736-18.503,22.678c-0.456,0.52-1.077,1.142-1.45,1.142 c-0.334,0-6.111-2.379-7.86-3.138c-40.041-17.393-70.433-59.219-74.601-66.272c-0.595-1.014-0.62-1.474-0.625-1.474 c0.146-0.537,1.493-1.887,2.188-2.583c2.033-2.011,4.236-4.663,6.367-7.228c1.009-1.215,2.02-2.432,3.012-3.579 c3.092-3.597,4.468-6.39,6.064-9.625l0.836-1.681c3.897-7.742,0.569-14.274-0.507-16.384c-0.883-1.765-16.643-39.803-18.319-43.799 c-4.03-9.643-9.354-14.133-16.753-14.133c-0.687,0,0,0-2.879,0.121c-3.506,0.148-22.598,2.661-31.039,7.983 c-8.952,5.644-24.096,23.633-24.096,55.271c0,28.474,18.07,55.359,25.828,65.584c0.193,0.258,0.547,0.781,1.061,1.533 c29.711,43.39,66.75,75.547,104.297,90.546c36.148,14.439,53.265,16.108,62.996,16.108c0.002,0,0.002,0,0.002,0 c4.089,0,7.362-0.321,10.25-0.605l1.832-0.175c12.487-1.107,39.929-15.327,46.171-32.673c4.917-13.663,6.214-28.591,2.942-34.008 C387.604,298.403,383.742,296.549,378.853,294.202z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} d="M260.545,0C121.879,0,9.066,111.965,9.066,249.588c0,44.512,11.912,88.084,34.479,126.218 L0.352,503.216c-0.805,2.375-0.206,5.002,1.551,6.791C3.172,511.302,4.892,512,6.649,512c0.673,0,1.351-0.101,2.013-0.313 l132.854-42.217c36.355,19.424,77.445,29.678,119.03,29.678C399.199,499.15,512,387.197,512,249.588 C512,111.965,399.199,0,260.545,0z M260.545,447.159c-39.13,0-77.029-11.299-109.608-32.677c-1.095-0.72-2.367-1.089-3.647-1.089 c-0.677,0-1.355,0.103-2.015,0.313l-66.552,21.155l21.484-63.383c0.695-2.051,0.347-4.314-0.933-6.063 c-24.809-33.898-37.923-73.949-37.923-115.827c0-108.955,89.357-197.597,199.191-197.597c109.821,0,199.168,88.642,199.168,197.597 C459.713,358.53,370.367,447.159,260.545,447.159z" />
        </svg>
      );
    default:
      return (
        <svg id="contact-icon-default" data-testid="contact-icon-default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector1" d="M5 8.5H2.5C2.22386 8.5 2 8.72386 2 9V15C2 15.2761 2.22386 15.5 2.5 15.5H5V8.5ZM2.5 7C1.39543 7 0.5 7.89543 0.5 9V15C0.5 16.1046 1.39543 17 2.5 17H6.5V7H2.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector2" d="M18.9 8.5H21.4C21.6762 8.5 21.9 8.72386 21.9 9V15C21.9 15.2761 21.6762 15.5 21.4 15.5H18.9V8.5ZM21.4 7C22.5046 7 23.4 7.89543 23.4 9V15C23.4 16.1046 22.5046 17 21.4 17H17.4V7H21.4Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector3" d="M13.75 22.5L11.25 22.5C10.8358 22.5 10.5 22.1642 10.5 21.75C10.5 21.3358 10.8358 21 11.25 21L13.75 21C14.1642 21 14.5 21.3358 14.5 21.75C14.5 22.1642 14.1642 22.5 13.75 22.5ZM11.25 24C10.0074 24 9 22.9926 9 21.75C9 20.5074 10.0074 19.5 11.25 19.5L13.75 19.5C14.9926 19.5 16 20.5074 16 21.75C16 22.9926 14.9926 24 13.75 24L11.25 24Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector4" d="M15 21.25C17.3472 21.25 19.25 19.3472 19.25 17H20.75C20.75 20.1756 18.1756 22.75 15 22.75H14.5V21.25H15Z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={color || '#FFFFFF'} id="vector5" d="M12 1.75C8.38512 1.75 5.5 4.57281 5.5 8L4 8C4 3.6952 7.60675 0.25 12 0.25C16.3933 0.249999 20 3.6952 20 8L18.5 8C18.5 4.57281 15.6149 1.75 12 1.75Z" />
        </svg>
      );
  }
};

module.exports = ContactInProgressIllustration;
