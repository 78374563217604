/**
 * Module dependencies
 */
const React = require('react');

const Location = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <circle id="location-a" cx="24" cy="24" r="24" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="location-b" fill="#fff">
        <use xlinkHref="#location-a" />
      </mask>
      <use fill="#FFF" xlinkHref="#location-a" />
      <g fill="#3483FA" fillRule="nonzero" mask="url(#location-b)">
        <path d="M24.528 35.186l-.53.53-.53-.53c-5.125-5.127-7.716-9.795-7.716-14.035a8.246 8.246 0 0 1 16.492 0c0 4.24-2.591 8.908-7.716 14.035zM17.252 21.15c0 3.627 2.231 7.781 6.746 12.436 4.515-4.655 6.746-8.809 6.746-12.436a6.746 6.746 0 0 0-13.492 0zm6.747 3.764a3.755 3.755 0 1 1 0-7.51 3.755 3.755 0 0 1 0 7.51zm0-1.5a2.255 2.255 0 1 0 0-4.51 2.255 2.255 0 0 0 0 4.51z" />
      </g>
    </g>
  </svg>
);

Location.propTypes = {};
Location.defaultProps = {};

module.exports = Location;
