/**
 * Module dependencies
 */
const React = require('react');
const { string, oneOfType, number, arrayOf } = require('prop-types');

const Title = ({ title, subtitle, orderNumber }) => {
  const subtitles = Array.isArray(subtitle) ? subtitle : [subtitle];
  return (
    <div className="widget-title">
      <p className="widget-title__title">{title}</p>
      {subtitles.map((sub) => (
        <p key={`widget-subtitle-${sub}`} className="widget-title__subtitle">
          {sub}
        </p>
      ))}
      {orderNumber && <p className="widget-title__order-number">{orderNumber}</p>}
    </div>
  );
};

Title.propTypes = {
  title: string,
  subtitle: oneOfType([string, arrayOf(string)]),
  orderNumber: oneOfType([string, number]),
};
Title.defaultProps = {
  title: '',
  subtitle: '',
  orderNumber: '',
};

module.exports = Title;
