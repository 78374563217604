const React = require('react');

const PlatformContext = React.createContext();

const PlatformProvider = ({ children, value }) => (
  <PlatformContext.Provider value={value}>
    {children}
  </PlatformContext.Provider>
);

const usePlatformState = () => {
  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error('UsePlatformState must be used within a provider');
  }

  return context;
};

module.exports = {
  PlatformProvider,
  usePlatformState,
};
