/**
 * Module dependencies
 */
const React = require('react');
const { string, objectOf, any } = require('prop-types');
const widgetChild = require('./widgetTypes');

const Widget = ({ type, widgetProps }) => {
  const WidgetChild = widgetChild[type];
  return (
    <div className="cx-peach__widget">
      <WidgetChild {...widgetProps} />
    </div>
  );
};

Widget.propTypes = {
  type: string,
  widgetProps: objectOf(any),
};

Widget.defaultProps = {
  type: 'search',
  widgetProps: {},
};

module.exports = Widget;
