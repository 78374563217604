/**
 * Module dependencies
 */
const React = require('react');
const {
  shape,
  string,
  oneOfType,
  node,
  arrayOf,
  objectOf,
  any,
  bool,
} = require('prop-types');
const Head = require('nordic/head');
const Script = require('nordic/script');
const serialize = require('serialize-javascript');
const classNames = require('classnames');
const { MLBUS } = require('../utils/constants');
const { executeNative } = require('../utils/mobile/nativeActions');
const Track = require('../components/Track');
const Widget = require('../components/Widget');
const Breadcrumb = require('../components/Breadcrumb');
const SuggestionSearch = require('../components/SuggestionSearch');

const { useI18n } = require('../utils/context/i18nContext');
const { SearchProvider } = require('../utils/context/search');
const { useLayoutState } = require('../utils/context/layout');
const { useFlags } = require('../utils/context/flags');
const { removeSecretToken } = require('../utils/sanitizePreloadedState');
const SearchSectionV2 = require('../components/SearchSectionV2');
const FloatingButtonModuleV4 = require('../components/FloatingButtonModuleV4');
const FloatingButtonModuleV2 = require('../components/FloatingButton');
const { isMobileSearchBtnDelegated, isSmartPOS } = require('../utils');
const { CxSearchVisibility } = require('../utils/mobile/constants');
const MelilabTag = require('../components/MelilabTag');
/**
 * Wrapper Component
*/

const Wrapper = (props) => {
  const {
    state,
    children,
    envPage,
    secretToken,
    platform,
    hotjarCode,
    widgetType,
    widgetProps,
    breadcrumbLevels,
    device,
    newPortalMP,
    urlState,
    reduxStore,
    pageId,
    actualLocation,
    content,
    verdiChatLink,
  } = props;

  const i18n = useI18n();
  const { portalView, isLanding, congrats, isZRP, showBreadcrumb, noSearch, displayFloatingButtonV4 = false, hasCreditRestriction = false, experimentTypes, isTopOff } = useLayoutState();
  const {
    'new-search': newSearch,
    'new-search-mp': newSearchMP,
    'portal-native-search-mp': portalNativeSearchMP,
    'portal-native-search-ml': portalNativeSearchML,
  } = useFlags();
  const { id: business, siteId, domain, countryId } = platform;
  const showNewSearch = MLBUS.includes(business)
    ? newSearch
    : newSearchMP;

  const showNativeSearch = MLBUS.includes(business)
    ? portalNativeSearchML
    : portalNativeSearchMP;


  const isSearchButtonV2Enabled = reduxStore.config?.isSearchButtonV2Enabled;
  const displaySearchSectionV2 = pageId === 'home' && isSearchButtonV2Enabled;

  const { isProd = true } = envPage;
  const { locale } = i18n;

  const { meta = {}, page } = state;
  const { indexable, description, tags, title: metaTitle, pageName } = meta;
  const customPortalTitleComponent = reduxStore?.config?.sections?.find(
    (c) => c.component === 'SearchSection',
  );
  const pageTitle = customPortalTitleComponent
    ? customPortalTitleComponent.pageTitle
    : '';
  const metaDescription = customPortalTitleComponent
    ? customPortalTitleComponent.metaDescription
    : '';
  const { webView: isNativeMobile, nativeApp } = device;
  const showDetailWidget = !(
    (showNewSearch || newPortalMP)
    && ['empty', 'search', 'relatedContents', undefined].includes(widgetType)
  );
  const witchMeta = true;
  const descriptionGen = (description && description.replace(/\r?\n|\r/g, ' '))
    || (metaDescription && metaDescription.replace(/\r?\n|\r/g, ' '))
    || '';
  const imagesMeta = {
    ML: 'https://http2.mlstatic.com/storage/cx-support-fcm-api/fcm-pub-os-prod/cx-support-mario-frontend/cvasquezcord/mercadolibre-og.png',
    MP: 'https://http2.mlstatic.com/resources/frontend/landings-mp/images/mercadopago-og.jpg',
  };

  const defaultTitles = {
    ML: i18n.gettext('Ayuda con Mercado Libre: soluciones, información y más'),
    MP: i18n.gettext('Ayuda con Mercado Pago: soluciones, información y más'),
  };
  const title = metaTitle || pageTitle || defaultTitles[business.toString()];
  const metaProperties = {
    ogUrl: `${urlState.homeUrl}${urlState.baseUrl}`,
    ogLocale: locale?.replace('-', '_'),
    ogType: 'website',
    ogTitle: title,
    ogDescription: descriptionGen,
    ogSiteName: domain,
    ogImage: imagesMeta[business],
  };
  const metaNames = {
    twitterCard: 'summary_large_image',
    twitterSite: business === 'MP' ? '@mercadopago' : '@mercadolibre',
    twitterTitle: title,
    twitterDescription: descriptionGen,
    twitterImage: imagesMeta[business],
  };

  const [isSearchBtnVisible, setIsSearchBtnVisible] = React.useState(false);
  const [meliLabTag, setMelilabTag] = React.useState('');
  const canAddMobileSearchBtn = isMobileSearchBtnDelegated(isSearchBtnVisible, nativeApp, showNativeSearch, isSearchButtonV2Enabled, actualLocation, pageId);
  const { headers, customPortalConfig, scope } = urlState;
  const isYoshi = headers['x-webkit-custom'];

  React.useEffect(() => {
    if (canAddMobileSearchBtn && isYoshi) {
      executeNative(CxSearchVisibility, { visible: true });
      setIsSearchBtnVisible(true);
    }
  }, []);

  const hasTags = tags && Array.isArray(tags) && tags.length > 0;

  const portalPrefix = headers ? headers['x-portal-prefix'] : null;
  if (typeof window !== 'undefined' && portalPrefix) {
    const navHeader = document ? document.getElementsByTagName('header') : null;
    const navLogo = navHeader && navHeader.length > 0
      ? navHeader[0].getElementsByClassName('nav-logo')
      : null;
    if (navLogo && navLogo.length > 0) {
      const customHref = customPortalConfig[portalPrefix]?.logoHref;
      navLogo[0].href = `/${customHref || portalPrefix}`;
    }
  }
  const breadCrumbVisible = (portalView && !isLanding) || showBreadcrumb;
  const preloadedState = serialize(removeSecretToken(props), { isJSON: true });

  const processId = content?.process_id ? content?.process_id : null;
  const contentId = content?.content_id ? content?.content_id : null;
  const contextFloatingButtonV4 = contentId && { processId, contentId };
  const isSmartpos = isSmartPOS(device.nativeApp);
  const experimentTypePointOfContact = experimentTypes?.['cx_portal/point_of_contact']?.configuration?.type || experimentTypes?.['cx_portal/point_of_contact_ML']?.configuration?.type;
  const showContactBtnV4 = !isTopOff && displayFloatingButtonV4 && !isSmartpos && (!experimentTypePointOfContact || (experimentTypePointOfContact === 'DEFAULT'));

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      const value = `; ${window.document.cookie}`;
      const parts = value.split('; meliLab=');
      if (parts.length === 2) {
        const melilabCookie = parts.pop().split(';').shift();
        if (melilabCookie === scope && scope !== 'production') setMelilabTag(melilabCookie);
      }
    }
  }, []);

  return (
    <div
      className={classNames('cx-peach-layout', {
        'layout-portal': portalView,
        'cx-peach-layout--zrp': isZRP,
        mobile: isNativeMobile,
        newSearch,
      })}
    >
      {Boolean(meliLabTag) && <MelilabTag scope={meliLabTag} isMercadoPago={business === 'MP'} />}
      {Boolean(page) && <Track statePage={page} isProd={isProd} />}
      <Script>
        {
          `window.__PRELOADED_STATE__ = ${preloadedState};
          ${hotjarCode}`
        }
      </Script>
      <Head>
        <title>{title}</title>
        {(!indexable || !isProd) && [
          <meta key="meta-robots" name="robots" content="noindex,follow" />,
          <meta
            key="meta-googlebot"
            name="googlebot"
            content="noindex,follow"
          />,
        ]}
        {description ? (
          <meta
            name="description"
            content={description && description.replace(/\r?\n|\r/g, ' ')}
          />
        ) : null}
        {metaDescription ? (
          <meta
            name="description"
            content={
              metaDescription && metaDescription.replace(/\r?\n|\r/g, ' ')
            }
          />
        ) : null}

        {witchMeta && <meta property="og:url" content={metaProperties.ogUrl} />}
        {witchMeta
          && (
            <meta property="og:locale" content={metaProperties.ogLocale} />
          )}
        {witchMeta
          && (
            <meta property="og:type" content={metaProperties.ogType} />
          )}
        {witchMeta
          && (
            <meta property="og:title" content={metaProperties.ogTitle} />
          )}
        {witchMeta
          && (
            descriptionGen ? (
              <meta
                property="og:description"
                content={metaProperties.ogDescription}
              />
            ) : null)}
        {witchMeta && (
          <meta property="og:site_name" content={metaProperties.ogSiteName} />
        )}
        {witchMeta
          && (
            <meta property="og:image" content={metaProperties.ogImage} />
          )}
        {witchMeta
          && (
            <meta name="twitter:card" content={metaNames.twitterCard} />
          )}
        {witchMeta
          && (
            <meta name="twitter:site" content={metaNames.twitterSite} />
          )}
        {witchMeta
          && (
            <meta name="twitter:title" content={metaNames.twitterTitle} />
          )}
        {witchMeta
          && (
            descriptionGen ? (
              <meta
                name="twitter:description"
                content={metaNames.twitterDescription}
              />
            ) : null)}
        {witchMeta && (
          <meta name="twitter:image" content={metaNames.twitterImage} />
        )}

        {hasTags && <meta name="keywords" content={tags.join(' ')} />}
        {Boolean(secretToken) && (
          <meta name="page-token" content={secretToken} />
        )}
      </Head>
      {showContactBtnV4 && (
        <FloatingButtonModuleV4
          bu={business}
          countryId={countryId}
          domain={domain}
          scope={scope}
          isMobile={isNativeMobile}
          isCbt={siteId === 'CBT'}
          context={contextFloatingButtonV4}
          hasCreditRestriction={hasCreditRestriction}
          verdiChatLink={verdiChatLink}
          loadingComponent={<FloatingButtonModuleV2 bu={business} isMobile={isNativeMobile} />}
        />
      )}
      {!noSearch ? (
        <SearchProvider>
          {displaySearchSectionV2 && <SearchSectionV2 />}
          {(showNewSearch || displaySearchSectionV2) && <SuggestionSearch bu={business} isSearchButtonV2Enabled={isSearchButtonV2Enabled} showNativeSearch={showNativeSearch} actualLocation={actualLocation} />}

          <div
            className={classNames('cx-peach-layout__wrapper', {
              'cx-peach-layout__home': !portalView,
              'cx-peach-layout__portal': portalView && !isLanding,
              'cx-peach-layout__landing': portalView && isLanding,
            })}
          >
            <div
              className={classNames('portal-content', {
                'portal-content__congrats': congrats,
                [`${pageName}-wrapper`]: pageName,
                'no-native-app': !nativeApp,
              })}
            >
              {breadCrumbVisible && (
                <Breadcrumb breadcrumbLevels={breadcrumbLevels} />
              )}
              {children}
            </div>
            {portalView && !isLanding && showDetailWidget && (
              <Widget
                type={widgetType}
                widgetProps={{ ...widgetProps, siteId }}
              />
            )}
          </div>
        </SearchProvider>
      ) : (
        children
      )}
    </div>
  );
};

Wrapper.propTypes = {
  hotjarCode: string,
  platform: shape({
    id: string.isRequired,
  }).isRequired,
  children: oneOfType([node, arrayOf(node)]),
  secretToken: string,
  envPage: shape({}),
  state: shape({}),
  widgetType: string,
  widgetProps: objectOf(shape(any)),
  breadcrumbLevels: arrayOf(
    shape({
      id: string,
      text: string,
      href: string,
    }),
  ),
  device: shape({ type: string }),
  newPortalMP: bool,
  urlState: shape({
    homeUrl: string,
    headers: shape({}),
  }),
  reduxStore: shape({}),
  mobileActiveChat: shape({ chatStartUrl: string }),
};

Wrapper.defaultProps = {
  hotjarCode: '',
  children: null,
  secretToken: '',
  envPage: {},
  state: {},
  widgetType: undefined,
  widgetProps: undefined,
  breadcrumbLevels: [],
  device: {},
  newPortalMP: false,
  urlState: { homeUrl: '', headers: {} },
  reduxStore: {},
  mobileActiveChat: {},
};

module.exports = Wrapper;
