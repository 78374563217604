const React = require('react');
const { bool, objectOf, any } = require('prop-types');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const serialize = require('serialize-javascript');

const Track = ({ statePage, isProd }) => {
  const trackReturn = statePage ? [
    statePage.analytics && (
      <div key="div-meliga" data-testid="meli-ga">
        <MeliGA key="meliga" {...statePage.analytics} />
      </div>
    ),
    statePage.melidata && (
      <div key="melidatatrack" data-testid="melidata-track">
        <MelidataTrack key="melidatatrack" {...statePage.melidata} experiments={statePage.melidata?.experiments} />
      </div>
    ),
  ] : null;

  const trackNotProdReturn = statePage ? (
    <div key="div-logtrack" data-testid="log-track">
      <Script>{`console.log('log-track:', ${serialize(statePage, { isJSON: true })})`}</Script>
    </div>
  ) : null;

  return isProd ? trackReturn : trackNotProdReturn;
};

Track.propTypes = {
  statePage: objectOf(any),
  isProd: bool,
};
Track.defaultProps = {
  statePage: null,
  isProd: true,
};

module.exports = Track;
