const React = require('react');

const DeviceContext = React.createContext();

const DeviceProvider = ({ children, value }) => (
  <DeviceContext.Provider value={value}>
    {children}
  </DeviceContext.Provider>
);

const useDeviceState = () => {
  const context = React.useContext(DeviceContext);
  if (!context) {
    throw new Error('UseDeviceState must be used within a provider');
  }

  return context;
};

module.exports = {
  DeviceProvider,
  useDeviceState,
};
