/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any } = require('prop-types');
const { Image } = require('nordic/image');

const PurchaseAmount = require('../Purchase/Amount');
const Attribute = require('./Attribute');

const ProductSummary = ({ data }) => (
  <li className="product-summary">
    <div className="product-summary__image">
      <Image src={data.image || 'https://static.mlstatic.com/org-img/preview/MLA/artsinfoto.gif'} alt={data.title} />
    </div>
    <div className="product-summary__data">
      <p className="product-summary__title">{data.title}</p>

      {(data.size_attribute || data.color_one_attribute) && (
        <Attribute
          sizeAttribute={data.size_attribute}
          colorOneAttribute={data.color_one_attribute}
          colorTwoAttribute={data.color_two_attribute}
          two={data.two}
        />
      )}

      <PurchaseAmount data={data.amount}>
        <span className="product-summary__quantity">{data.quantity}</span>
      </PurchaseAmount>
    </div>
  </li>
);

ProductSummary.propTypes = {
  data: objectOf(any).isRequired,
};
ProductSummary.defaultProps = {};

module.exports = ProductSummary;
