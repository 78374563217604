const React = require('react');
const { getUrls } = require('..');

const UrlContext = React.createContext();

const UrlProvider = ({ children, value }) => {
  const helpUrls = getUrls(value);
  return <UrlContext.Provider value={helpUrls}>{children}</UrlContext.Provider>;
};

const useUrlState = () => {
  const context = React.useContext(UrlContext);
  if (!context) {
    throw new Error('UseUrlState must be used within a provider');
  }

  return context;
};

module.exports = {
  UrlProvider,
  useUrlState,
};
