/**
 * Module dependencies
 */
const React = require('react');
// const { objectOf, any } = require('prop-types');
const { getDeepText } = require('../controller/utils');

const StatusCard = require('../components/StatusCard');

const ErrorReason = ({ text = '' }) => <p>{text}</p>;

const Description = ({ firstPayment, secondPayment }) => {
  let response = [
    <p key="method-description__firstPayment.description.text">{getDeepText(firstPayment, 'description.text')}</p>,
  ];

  if (secondPayment) {
    response = [
      ...response,
      <p key="method-description__firstPayment.title.text">{getDeepText(firstPayment, 'title.text')}</p>,
      <p key="method-description__secondPayment.description.text">{getDeepText(secondPayment, 'description.text')}</p>,
      <p key="method-description__secondPayment.title.text">{getDeepText(secondPayment, 'title.text')}</p>,
    ];
  }

  return response;
};

const Method = ({
  icon,
  status_icon: statusIcon,
  payment_status: paymentStatus,
  title,
  subtitle,
  failure,
  error_reason: errorReason,
  first_payment: firstPayment,
  second_payment: secondPayment,
}) => (
  <StatusCard icon={icon} statusIcon={statusIcon} labelStatus={paymentStatus.text} title={`${title} ${subtitle}`}>
    {failure && failure.text && <div dangerouslySetInnerHTML={{ __html: failure.text }} />}
    {errorReason ? (
      <ErrorReason text={errorReason.text} />
    ) : (
      <Description firstPayment={firstPayment} secondPayment={secondPayment} />
    )}
  </StatusCard>
);

Method.propTypes = {};
Method.defaultProps = {};

module.exports = Method;
