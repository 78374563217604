const React = require('react');

const ContactInProgress = require('./ContactInProgress');

const FloatingButton = (props) => {
  const {
    onClick,
    isMobile,
    bu,
  } = props;

  const propsContactInProgress = {
    onClick,
    isMobile,
    bu,
  };

  return (
    <div className="floating-button__container">
      <ContactInProgress {...propsContactInProgress} />
    </div>
  );
};

module.exports = FloatingButton;
