const { useCallback, useEffect, useRef } = require('react');

const useCallActionOnFocused = ({
  action,
  isMobile,
  isInitialInvokeDone = false,
  callbackAfterInvokeAction = null,
}) => {
  const memoizedAction = useCallback(() => {
    action();
    if (callbackAfterInvokeAction) {
      callbackAfterInvokeAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewAppearedSubscriptionIdRef = useRef(null);

  const subscribeToMobileEvents = useCallback(() => {
    if (window.MobileWebKit) {
      onViewAppearedSubscriptionIdRef.current = window.MobileWebKit.lifecycle.onViewAppeared(() => memoizedAction());
    }
  }, [memoizedAction]);

  const unsubscribeToMobileEvents = useCallback(
    (onViewAppearedSubscriptionId) => {
      if (!window.MobileWebKit) return;

      if (onViewAppearedSubscriptionId) {
        window.MobileWebKit.lifecycle.unsubscribe(onViewAppearedSubscriptionId);
      }
    },
    [],
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        memoizedAction();
      }
    };

    if (isMobile && window.MobileWebKit) {
      subscribeToMobileEvents();
    } else {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    if (!isInitialInvokeDone) {
      memoizedAction();
    }

    return () => {
      if (isMobile && window.MobileWebKit) {
        unsubscribeToMobileEvents(onViewAppearedSubscriptionIdRef.current);
      } else {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      }
    };
  }, [memoizedAction, isMobile, subscribeToMobileEvents, unsubscribeToMobileEvents, isInitialInvokeDone]);

  return null;
};

module.exports = {
  useCallActionOnFocused,
};
