/**
 * Module dependencies
 */
const React = require('react');
const { objectOf, any } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const Method = require('./Method');
const BillSection = require('./BillSection');
const StatusCard = require('../components/StatusCard');

const Bill = ({
  i18n,
  paymentMethods,
  purchase,
  toBeAgreed,
  icon,
  status_icon: statusIcon,
  title,
  expenses,
  refounds,
  payments,
  total,
  followingOperations,
}) => {
  let statusCardData;
  if (!paymentMethods || !paymentMethods.length) {
    const isCancelled = purchase.status === 'cancelled';
    let labelStatus = i18n.gettext('Pago a convenir');
    let iconValue = icon;
    let statusIconValue = statusIcon;
    const statusLabelValue = statusIcon;
    if (isCancelled) {
      labelStatus = i18n.gettext('Compra cancelada');
      iconValue = 'ticket';
      statusIconValue = 'error';
    } else if (!toBeAgreed) {
      labelStatus = i18n.gettext('Pago pendiente');
      iconValue = 'pago';
      statusIconValue = 'warning';
    }

    statusCardData = {
      icon: iconValue,
      statusIcon: statusIconValue,
      statusLabel: statusLabelValue,
      labelStatus,
    };
  }

  return (
    <div className="payment-bill">
      <div className="payment-bill__status-cards">
        {!paymentMethods || !paymentMethods.length ? (
          <StatusCard {...statusCardData} />
        ) : (
          paymentMethods.map((paymentMethod) => <Method key={`payment-method-${paymentMethod.title}`} {...paymentMethod} />)
        )}
      </div>

      <p className="payment-bill__subtitle">{title}</p>

      <div className="payment-bill__table">
        <BillSection items={expenses} />
        <BillSection items={payments} isTotal separator={Boolean(expenses)} />
        {total && <BillSection items={[total]} separator={Boolean(payments)} />}
        {refounds && <BillSection items={[refounds]} separator />}
        {followingOperations && <BillSection items={followingOperations} />}
      </div>
    </div>
  );
};
Bill.propTypes = {
  data: objectOf(any),
};
Bill.defaultProps = {};

module.exports = injectI18n(Bill);
