/**
 * Module dependencies
 */
const React = require('react');
const { shape, func } = require('prop-types');
const { connect } = require('react-redux');
const injectI18n = require('nordic/i18n/injectI18n');

const Title = require('../components/Title');
const Collapsible = require('../components/Collapsible');
const PaymentBill = require('../Payment/Bill');
const PackageDetail = require('./PackageDetail');
const ShippingDetails = require('./ShippingDetails');

const Purchase = ({ i18n, header, shipping, packs, isReservation, isContract, bill, toBeAgreed }) => {
  const baseClass = 'widget-purchase';

  return (
    <div className={baseClass}>
      <Title {...header} />

      <p className={`${baseClass}__head-arrival`}>{shipping && shipping.text ? shipping.text : ''}</p>

      { packs.map((pack, idx) => (
        <div key={`pack-${pack.shipping_id}`}>
          {!isReservation && !isContract && (
            <p className={`${baseClass}__pack-title`}>{i18n.gettext('Paquete {0} de {1}', [idx + 1, packs.length])}</p>
          )}
          <PackageDetail pack={pack} packsCount={packs.length} index={idx} />
        </div>
      ))}

      {shipping && !isReservation && !isContract && <ShippingDetails {...shipping} />}

      {bill && (
        <Collapsible title={i18n.gettext('Pago')} baseClass={baseClass}>
          <PaymentBill {...bill} toBeAgreed={toBeAgreed} />
        </Collapsible>
      )}
    </div>
  );
};

Purchase.propTypes = {
  i18n: shape({ gettext: func.isRequired }).isRequired,
};
Purchase.defaultProps = {};

const mapStateToProps = ({ widgetDetails: { purchase } }) => ({
  ...purchase,
});

const ConectedPurchase = injectI18n(
  connect(
    mapStateToProps,
    {},
  )(Purchase),
);

module.exports = ConectedPurchase;
