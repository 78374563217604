const React = require('react');
const { getOptions } = require('./selector');

const { useReducer } = React;

const SearchReducer = (state, action) => {
  switch (action.type) {
    case 'setSearchValue': {
      const newState = { ...state, value: action.data, mask: '', selectedIndex: undefined, isSearchValue: true };
      return { ...newState, options: getOptions(newState) };
    }
    case 'setSearchFocus':
      return { ...state, focus: action.data };
    case 'setSearchPosition':
      return { ...state, position: action.data };
    case 'setOptions': {
      const newState = { ...state, history: action.data.history, contents: action.data.contents, limit: action.limit };
      return { ...newState, options: getOptions(newState) };
    }
    case 'setSelectedOptions':
      return { ...state, selectedIndex: action.data };
    case 'setNextOption': {
      const { selectedIndex, options } = state;
      const maxIndex = options.length - 1;
      const nextIndex = selectedIndex === undefined ? 0 : selectedIndex + 1;
      const nextselectedIndex = nextIndex > maxIndex ? 0 : nextIndex;
      return {
        ...state,
        mask: state.value ? '' : state?.options[nextselectedIndex]?.title,
        selectedIndex: nextselectedIndex,
      };
    }
    case 'setPrevOption': {
      const { selectedIndex, options } = state;
      const maxIndex = options.length - 1;
      const prevIndex = selectedIndex === undefined ? maxIndex : selectedIndex - 1;
      const prevselectedIndex = prevIndex < 0 ? maxIndex : prevIndex;
      return {
        ...state,
        mask: state.value ? '' : state?.options[prevselectedIndex]?.title,
        selectedIndex: prevselectedIndex,
      };
    }
    default:
      throw new Error('Failed mapping SearchReducer action');
  }
};

const useSearchReducer = () => {
  const initialState = {
    value: '',
    mask: '',
    options: [],
    limit: 5,
    focus: false,
    isSearchValue: false,
    selectedIndex: undefined,
    position: { top: 0, left: 0, width: '100%', height: 0 },
  };

  return useReducer(SearchReducer, initialState);
};

module.exports = {
  useSearchReducer,
};
